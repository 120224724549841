<template>
  <span>
    <ellipse-icon
      :width="ellipseSize ? `${ellipseSize}px` : '59px'"
      :height="ellipseSize ? `${ellipseSize}px` : '59px'"
      border="2px"
      :variant="variant"
      :fill="background"
      v-slot="props"
    >
      <img
        v-if="traceName"
        :src="`${baseUrl}/icons/trace/${traceName}?color=${encodeURIComponent(
          props.fill
        )}${sizeQuery}`"
      />
    </ellipse-icon>
  </span>
</template>

<script>
import * as conf from "../../../../conf.yml";

export default {
  name: "TraceServicesIcon",
  props: {
    traceName: {
      type: String,
      default: null,
    },
    variant: {
      type: String,
      default: "primary",
    },
    background: {
      type: String,
      default: "white",
    },
    ellipseSize: {
      type: Number,
      default: undefined,
    },
    iconSize: {
      type: Number,
      default: undefined,
    },
  },
  computed: {
    baseUrl() {
      return conf.default.server.host;
    },
    sizeQuery() {
      if (this.iconSize) {
        return `&width=${this.iconSize}&height=${this.iconSize}`;
      }
      return "";
    },
  },
};
</script>

<style scoped></style>
