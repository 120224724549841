<template>
  <div class="image-uploader">
    <div class="image-container">
      <label for="fileInput" class="icon-circle">
        <v-icon class="add-img-icon">mdi-plus</v-icon>
      </label>
      <input
        id="fileInput"
        type="file"
        accept="image/*"
        hidden
        @change="onFileChange"
      />
      <embed
        v-if="!uploading"
        :src="file.url"
        alt="sample image"
        class="image"
      />
      <div v-else>
        <md-progress-spinner
          class="color_stroke"
          md-mode="indeterminate"
        ></md-progress-spinner>
      </div>
      <div v-if="!file?.isDefault" class="info-panel">
        <strong class="image-name">{{ file.title }}</strong>
        <span class="image-size">{{
          file.size > 1024
            ? (file.size / 1024).toFixed(2) + "MB"
            : file.size + "KB"
        }}</span>
      </div>
    </div>
    <div class="hint-container">
      <strong class="hint"> Best Image Resolution: 500x500 </strong>
      <strong class="hint"> Max File Size: 50mb </strong>
    </div>
  </div>
</template>

<script>
export default {
  name: "ImageUploader",
  props: {
    file: {
      type: Object,
      required: true,
      default: null,
    },
    uploading: {
      type: Boolean,
      required: true,
      default: false,
    },
  },

  data() {
    return {
      isUploading: this.uploading,
    };
  },

  watch: {
    uploading(newValue) {
      this.isUploading = newValue;
    },
  },

  methods: {
    onFileChange(event) {
      const selectedFile = event.target.files[0];
      if (selectedFile) {
        this.isUploading = true;
        this.$emit("update:uploading", true);
        this.$emit("upload", selectedFile);
      }
    },
  },
};
</script>

<style scoped>
.image-uploader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 170px;
  height: 170px;
  border: 3px solid #442d65;
  border-radius: 10px;
  overflow: hidden;
  padding: 0px;
}

.image {
  object-fit: contain;
  max-height: 100%;
  max-width: 100%;
  margin: 0px;
}

.icon-circle {
  position: absolute;
  cursor: pointer;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #00000040;
  backdrop-filter: blur(8px);
  z-index: 2;
  transform: translateY(-220%);
  transition: transform 0.3s ease-in-out;
}

.icon-circle:hover {
  border: 2px solid #5a3d79;
}

.add-img-icon {
  font-size: 30px;
  font-weight: 600;
  color: #ffffff;
}

.image-container:hover .icon-circle {
  transform: translateY(0);
}

.info-panel {
  width: 100%;
  max-height: fit-content;
  min-height: fit-content;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  bottom: 0;
  z-index: 2;
  background: rgba(82, 54, 123, 0.5);
  backdrop-filter: blur(8px);
  transform: translateY(100%);
  transition: transform 0.3s ease-in-out;
  padding: 5px 5px 1px 5px;
}

.image-container:hover .info-panel {
  transform: translateY(0);
}

.image-name {
  font-size: 11px;
  text-transform: uppercase;
  color: #ffffff;
  font-weight: 700;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 3px;
}

.image-size {
  font-size: 10px;
  color: #e3c9ff;
}

.hint-container {
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-top: 5px;
}

.hint {
  font-size: 10px;
  color: #0000006d;
}
</style>
