<template>
  <popup-window
    :active="active"
    title="Supply Chain Icons"
    :hasBack="false"
    :noMargin="true"
    :max-width="360"
    @popup-close="resetForms"
  >
    <div class="main-container">
      <div class="search-container">
        <input
          v-model="search"
          class="search-field"
          type="text"
          placeholder="Search icon"
          id="icon_name"
          name="icon_name"
          @input="searchIcons"
        />
      </div>
      <div class="scrollable-area">
        <div v-if="traceIcons?.length > 0" class="icon-grid">
          <div
            v-for="icon in traceIcons"
            :key="icon.id"
            class="icon-grid-item"
            @click="selectIcon(icon)"
          >
            <img
              :src="`${baseUrl}/icons/trace/${icon.name}`"
              :alt="icon.name"
              class="icon-image"
              loading="lazy"
            />
          </div>
        </div>
        <infinite-loading
          ref="InfiniteLoading"
          @infinite="infiniteHandler"
          spinner="spiral"
          @distance="10"
          force-use-infinite-wrapper
          :key="`infinite-toggle-${search}`"
        >
          <div slot="no-more" v-if="traceIcons.length">No more icons</div>
          <div slot="no-more" v-if="!traceIcons.length">No icons found</div>
          <div slot="no-results" v-if="traceIcons.length">No more icons</div>
          <div slot="no-results" v-if="!traceIcons.length">No icons found</div>
        </infinite-loading>
      </div>
    </div>
  </popup-window>
</template>

<script>
import * as conf from "../../conf.yml";
import restAdapter from "../../restAdapter";
import debounce from "lodash/debounce";
import InfiniteLoading from "vue-infinite-loading";

export default {
  name: "IconSelector",
  components: { InfiniteLoading },
  props: {
    active: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    search: "",
    traceIcons: [],
    hasMore: true,
    currentPage: 0,
    cachedIcons: {},
  }),
  computed: {
    baseUrl() {
      return conf.default.server.host;
    },
  },
  watch: {
    active: {
      handler(newVal) {
        if (newVal) {
          this.loadCachedIcons();
        }
      },
      immediate: true,
    },
  },
  methods: {
    loadCachedIcons() {
      if (this.cachedIcons[this.search]) {
        const { icons, hasMore, currentPage } = this.cachedIcons[this.search];
        this.traceIcons = icons;
        this.hasMore = hasMore;
        this.currentPage = currentPage;
      } else {
        this.loadIcons();
      }
    },
    searchIcons: debounce(function () {
      this.currentPage = 0;
      this.hasMore = true;
      this.currentPage = 0;
      delete this.cachedIcons[this.search];
    }, 300),
    infiniteHandler($state) {
      setTimeout(() => {
        this.loadIcons($state);
      }, 1000);
    },
    loadIcons($state) {
      if (!this.hasMore) {
        $state?.complete();
        return;
      }
      restAdapter
        .get(
          `/api/trace/icons?page=${this.currentPage + 1}&search=${this.search}`
        )
        .then(({ data }) => {
          if (this.currentPage === 0) this.traceIcons = [];
          this.traceIcons.push(...data.icons);
          this.hasMore = data.hasMore;

          this.cachedIcons[this.search] = {
            icons: [...this.traceIcons],
            hasMore: this.hasMore,
            currentPage: this.currentPage + 1,
          };

          if (this.hasMore) {
            this.currentPage++;
            $state?.loaded();
          } else {
            $state?.complete();
          }
        })
        .catch((error) => {
          console.error("Error fetching icons:", error);
          $state?.error();
        });
    },
    selectIcon(icon) {
      this.$emit("icon-selected", icon);
      this.resetForms();
    },
    resetForms() {
      this.search = "";
      this.$emit("popup-close");
    },
  },
};
</script>

<style lang="scss" scoped>
.main-container {
  display: inline-block;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 350px;
  max-width: 100%;
  padding: 10px 28px;
  overflow: hidden;
}
.search-container {
  width: 100%;
}
.search-field[type="text"],
select {
  height: 30px;
  width: 100%;
  border: 1.5px solid $primaryDark;
  border-radius: 4px;
  color: #492a67;
  padding: 0 10px;
}
.search-field:focus {
  outline: none;
}
.scrollable-area {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
  width: 100%;
  min-height: 140px;
  max-height: 300px;
  margin-top: 10px;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 2px;
}
.icon-grid {
  max-width: 290px;
  height: fit-content;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-auto-rows: auto;
  gap: 5px;
  justify-content: center;
  align-items: flex-start;
  overflow: visible;
}
.icon-grid-item {
  width: 42px;
  height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 3px;
  cursor: pointer;
  transition: transform 0.2s ease;
}
.icon-grid-item:hover {
  border: 1.5px solid #433550d4;
  box-shadow: 0 0 3px 0 rgba(13, 0, 24, 0.2);
  transform: scale(1.1);
}
.icon-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
</style>
