var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"parent-container",style:({
    background: _vm.colorCodes.backgroundColor,
    '--trace-icon-color': _vm.colorCodes.iconColor,
  }),attrs:{"fluid":""}},[(_vm.notFoundTrace)?_c('div',{staticStyle:{"height":"100%","width":"100%"}},[_c('NotFound')],1):(_vm.isPendingTrace)?_c('div',{staticStyle:{"height":"100%","width":"100%"}},[_c('TracePending')],1):(_vm.loading)?_c('div',{staticClass:"text-center",staticStyle:{"margin":"auto"}},[_c('md-progress-spinner',{staticClass:"color_stroke",attrs:{"md-mode":"indeterminate"}})],1):_c('div',{staticClass:"child-container"},[_c('v-row',[_c('v-col',[_c('div',{staticClass:"traceability",style:({
            color: _vm.colorCodes.titleColor,
          })},[_vm._v(" Digital Product Passport ")]),(_vm.brandLogoImg)?_c('div',{staticClass:"brand-logo"},[(!_vm.linkClick || !_vm.brandLink)?_c('img',{staticClass:"logo-img",attrs:{"src":_vm.brandLogoImg,"alt":"brandLogo"}}):_c('a',{attrs:{"target":"_blank","href":`//${_vm.brandLink.split('//').pop()}`}},[_c('img',{staticClass:"logo-img",attrs:{"src":_vm.brandLogoImg,"alt":"brandLogo"}})])]):_vm._e(),_c('hr',{staticClass:"hr_devider_horizontal",style:(`background: linear-gradient(to right, ${_vm.colorCodes.iconColor}, ${_vm.titleDividerGradientEndColor});`)}),_c('div',{staticClass:"product-name",style:({ color: _vm.colorCodes.titleColor })},[_vm._v(" "+_vm._s(_vm.productName)+" ")]),(_vm.showToggle)?_c('div',{staticStyle:{"margin-top":"-5px","margin-bottom":"20px"}},[_c('span',{staticClass:"switch btn-color-mode-switch"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.versionChecked),expression:"versionChecked"}],attrs:{"type":"checkbox","name":"version","id":"version","value":"1"},domProps:{"checked":Array.isArray(_vm.versionChecked)?_vm._i(_vm.versionChecked,"1")>-1:(_vm.versionChecked)},on:{"change":function($event){var $$a=_vm.versionChecked,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v="1",$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.versionChecked=$$a.concat([$$v]))}else{$$i>-1&&(_vm.versionChecked=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.versionChecked=$$c}}}}),_c('label',{staticClass:"btn-color-mode-switch-inner",attrs:{"for":"version","data-on":"Current Version","data-off":"Updated Version"}})])]):_vm._e(),(
            !_vm.linkClick &&
            _vm.publicLink &&
            (!_vm.showToggle || (_vm.showToggle && _vm.currentVersion))
          )?_c('div',[_c('div',{staticClass:"public-link"},[_vm._v("Get sharable public link")]),_c('div',{staticClass:"url-div"},[_c('v-icon',{staticStyle:{"color":"#501d5f","transform":"rotate(120deg)"}},[_vm._v("link")]),_c('span',{staticClass:"url-border"}),_c('span',{staticClass:"url-span",attrs:{"id":"toCopy"}},[_vm._v(_vm._s(_vm.trackingCode || _vm.publicLink))]),_c('md-button',{class:_vm.copied ? 'copy-class' : 'copied-class',on:{"click":_vm.copying}},[_vm._v(_vm._s(_vm.copied ? "Copied!" : "Copy"))])],1)]):_vm._e(),(_vm.qr && (!_vm.showToggle || (_vm.showToggle && _vm.currentVersion)))?_c('div',[_c('qr-code',{attrs:{"qr":_vm.qr}})],1):_vm._e()])],1),_c('v-row',[_c('v-col',[_c('div',{staticClass:"embed"},[_c('img',{staticClass:"centered-and-cropped",style:({
              border: `1px solid ${_vm.colorCodes.titleColor}`,
              maxHeight: '50vh',
            }),attrs:{"src":_vm.defaultImage
                ? `${_vm.baseUrl}/api/inquiries/file_show/${_vm.defaultImage}`
                : _vm.sampleImage,"alt":"sample image"}})])])],1),(_vm.geoLocations && _vm.geoLocations.length)?_c('v-row',[_c('v-col',[_c('div',{staticClass:"more-info-container"},[_c('l-map',{ref:"map",staticClass:"geo-map",style:(`
              height: 300px;
              z-index: 2;
              border: 1px solid ${_vm.colorCodes.titleColor};
              border-radius: 7px;
              background-color: #aad3e0;
            `),attrs:{"options":{ attributionControl: false }}},[_c('l-tile-layer',{attrs:{"url":"https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png","attribution":"<a href='https://openstreetmap.org'>OpenStreetMap</a>","noWrap":""}}),_c('l-layer-group',_vm._l((_vm.geoLocations),function(geo,index){return _c('l-marker',{key:`geo${index}`,attrs:{"lat-lng":[geo.latitude, geo.longitude]}},[_c('l-icon',{attrs:{"icon-size":[40, 55],"icon-anchor":[20, 55]}},[_c('div',[_c('div',{staticClass:"geo-marker",style:(`background-color: ${_vm.colorCodes.iconColor}`)},[_c('div',{staticClass:"geo-marker-image",style:(`background-image: url(${`${geo.icon}`});`)})])])]),_c('l-tooltip',[_vm._v(_vm._s(geo.name))])],1)}),1),_c('l-control-attribution',{attrs:{"position":"bottomright","prefix":""}})],1)],1)])],1):_vm._e(),(_vm.traceView === 'expanded' && _vm.fabricComposition)?_c('v-row',[_c('v-col',[_c('div',{staticClass:"more-info-container"},[_c('trace-component-item',{attrs:{"color-codes":_vm.colorCodes,"fabric-composition":_vm.fabricComposition,"isCompact":false,"isMobile":true}})],1)])],1):_vm._e(),(_vm.traceView === 'expanded' && _vm.careInstructions.length)?_c('v-row',[_c('v-col',[_c('div',{staticClass:"more-info-container"},[_c('trace-component-item',{attrs:{"color-codes":_vm.colorCodes,"care-instructions":_vm.careInstructions,"isCompact":false,"isMobile":true}})],1)])],1):_vm._e(),(_vm.traceView === 'expanded' && _vm.sustainability)?_c('v-row',[_c('v-col',[_c('div',{staticClass:"more-info-container"},[_c('trace-component-item',{attrs:{"color-codes":_vm.colorCodes,"sustainability":_vm.sustainability,"isCompact":false,"isMobile":true}})],1)])],1):_vm._e(),(
        _vm.traceView === 'expanded' &&
        ((_vm.recycles && _vm.recycles.length) ||
          (_vm.repairs && _vm.repairs.length) ||
          (_vm.donations && _vm.donations.length) ||
          (_vm.upcycles && _vm.upcycles.length) ||
          (_vm.resales && _vm.resales.length))
      )?_c('v-row',[_c('v-col',[_c('div',{staticClass:"more-info-container"},[_c('trace-component-item',{attrs:{"color-codes":_vm.colorCodes,"isRepairResaleRecycle":true,"resales":_vm.resales,"recycles":_vm.recycles,"repairs":_vm.repairs,"donations":_vm.donations,"upcycles":_vm.upcycles,"isCompact":false,"isMobile":true,"entireTraceUnverified":_vm.entireTraceUnverified}})],1)])],1):_vm._e(),(_vm.traceView === 'expanded')?_c('v-row',[_c('v-col',[(_vm.displaySupplyChain)?_c('div',{staticClass:"more-info-container supply-chain-label"},[_c('trace-component-item',{attrs:{"color-codes":_vm.colorCodes,"displaySupplyChain":_vm.displaySupplyChain,"supply-chain":_vm.traceDetails,"delivery-methods":_vm.deliveryMethods,"isCompact":false,"isMobile":true,"entireTraceUnverified":_vm.entireTraceUnverified}})],1):_vm._e()])],1):_vm._e(),(_vm.traceView === 'expanded' && _vm.bomItems && _vm.bomItems.length)?_c('v-row',[_c('v-col',[_c('div',{staticClass:"more-info-container"},[_c('trace-component-item',{attrs:{"color-codes":_vm.colorCodes,"bom":_vm.bomItems,"isCompact":false,"isMobile":true,"entireTraceUnverified":_vm.entireTraceUnverified}})],1)])],1):_vm._e(),(_vm.traceView === 'compact')?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('trace-compact',{attrs:{"fabricComposition":_vm.fabricComposition,"careInstructions":_vm.careInstructions,"sustainability":_vm.sustainability,"displaySupplyChain":_vm.displaySupplyChain,"supplyChain":_vm.traceDetails,"bom":_vm.bomItems,"deliveryMethods":_vm.deliveryMethods,"colorCodes":_vm.colorCodes,"entireTraceUnverified":_vm.entireTraceUnverified,"isAddReviewVisible":_vm.isAddReviewVisible,"styleNumber":_vm.$route.params.styleNumber,"isPreview":_vm.preview,"isReviewComponent":!_vm.forceHideReviewComponent && _vm.isReviewComponentVisible,"isMobile":true,"isRepairResaleRecycle":(_vm.recycles && _vm.recycles.length) ||
            (_vm.repairs && _vm.repairs.length) ||
            (_vm.donations && _vm.donations.length) ||
            (_vm.upcycles && _vm.upcycles.length) ||
            (_vm.resales && _vm.resales.length),"resales":_vm.resales,"recycles":_vm.recycles,"repairs":_vm.repairs,"donations":_vm.donations,"upcycles":_vm.upcycles}})],1)],1):_vm._e(),(
        _vm.displaySupplyChain ||
        (_vm.bomItems && _vm.bomItems.length) ||
        (_vm.recycles && _vm.recycles.length) ||
        (_vm.repairs && _vm.repairs.length) ||
        (_vm.donations && _vm.donations.length) ||
        (_vm.upcycles && _vm.upcycles.length) ||
        (_vm.resales && _vm.resales.length)
      )?_c('div',{staticClass:"statuses",attrs:{"tabindex":"0"}},_vm._l((_vm.statuses),function(status,index){return _c('div',{key:`statuses${index}`,staticClass:"supplier-status"},[_c('v-tooltip',{ref:'tooltip' + index,refInFor:true,attrs:{"top":"","color":status === 'Verified'
              ? _vm.colorCodes.verifiedColor
              : _vm.colorCodes.unverifiedColor},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',{attrs:{"data-app":""}},[_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('div',[_c('v-icon',{style:({
                      color:
                        status === 'Verified'
                          ? _vm.colorCodes.verifiedColor
                          : _vm.colorCodes.unverifiedColor,
                      'margin-top': '-5px',
                    })},[_vm._v("circle")]),_c('span',{style:({
                      color:
                        status === 'Verified'
                          ? _vm.colorCodes.verifiedColor
                          : _vm.colorCodes.unverifiedColor,
                      'margin-top': '20px',
                      'margin-left': '15px',
                    })},[_vm._v(" "+_vm._s(" " + status))])],1)])])]}}],null,true)},[_c('div',{staticClass:"description-child-container"},[_c('div',{staticClass:"mt-1"},[_vm._v(" "+_vm._s(status === "Verified" ? "Seamless Source verifies the authenticity of registered businesses based on the documents they submit. Each business then independently verifies transactions through the Seamless Source platform and technology." : "Seamless Source does not have sufficient information to validate this business's authenticity. Each business then independently verifies transactions through the Seamless Source platform and technology.")+" ")])])])],1)}),0):_vm._e(),(
        _vm.traceView === 'expanded' &&
        !_vm.forceHideReviewComponent &&
        _vm.isReviewComponentVisible
      )?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"more-info-container"},[_c('trace-component-item',{attrs:{"isAddReviewVisible":_vm.isAddReviewVisible,"styleNumber":_vm.$route.params.styleNumber,"isPreview":_vm.preview,"colorCodes":_vm.colorCodes,"isReviewComponent":true,"isCompact":false,"isMobile":true}})],1)])],1):_vm._e()],1),(!_vm.notFoundTrace && !_vm.isPendingTrace && !_vm.loading)?_c('div',{staticClass:"logo_container_seamless",style:(_vm.seamlessLogoContainerStyle)},[_c('div',{staticClass:"powered-by-label"},[_c('span',[_vm._v("Powered By")]),(!_vm.linkClick)?_c('img',{staticClass:"logo-img-seamless",attrs:{"src":require("../../assets/seamless-source-banner.png"),"alt":"Seamless Source"}}):_c('a',{attrs:{"href":"https://seamlesssource.com/"}},[_c('img',{staticClass:"logo-img-seamless",attrs:{"src":require("../../assets/seamless-source-banner.png"),"alt":"Seamless Source"}})])])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }