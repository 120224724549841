<template>
  <div v-if="loading" class="text-center" style="height: 500px">
    <md-progress-spinner
      class="color_stroke"
      md-mode="indeterminate"
    ></md-progress-spinner>
  </div>
  <div v-else class="main_container">
    <v-row class="mb-1">
      <v-col>
        <div class="message-div">
          <div class="message-waiting" v-if="needTraceApproval && !isPublished">
            <span>Waiting to be published...</span>
          </div>
          <div
            class="message-changes-waiting"
            v-else-if="needTraceApproval && isPublished"
          >
            <span>Changes available to be published...</span>
          </div>
          <div class="message-done" v-else-if="isPublished">
            <span>Available for general public...</span>
          </div>
          <v-divider />
        </div>
      </v-col>
      <v-dialog v-model="iframe" width="unset">
        <div
          style="
            background-color: white;
            text-align: right;
            padding-bottom: 10px;
          "
        >
          <v-icon
            :style="`padding: 10px 10px 0px 0px; font-size: 23px; ${
              copiedFrameCode ? 'color: #70D0CE;' : 'color: #501D5F;'
            }`"
            @click="copying('toCopyFrameCode')"
            >{{ copiedFrameCode ? "done_all" : "file_copy" }}
          </v-icon>

          <pre class="ddp-code">
            <code id="toCopyFrameCode" class="code">
              <span class="tag">&lt;iframe</span>
              <span class="attr">  src</span>=<span class="string">"{{publicLink}}"</span>
              <span class="attr">  title</span>=<span class="string">"Digital Product Passport"</span><span class="tag">&gt;</span>
              <span class="tag">&lt;/iframe&gt;</span>
            </code>
          </pre>
        </div>
      </v-dialog>
      <v-dialog v-model="qrDialog" width="unset" class="qr-code-dialog">
        <qr-code
          class="qr-code-comp"
          :qr="qr"
          :styleNumber="styleNumber"
          :trackingCode="trackingCode"
          centerAlign
        />
      </v-dialog>
      <v-dialog v-model="publicLinkDialog" width="unset" class="qr-code-dialog">
        <div style="background-color: white; text-align: right; padding: 20px">
          <div
            style="
              font-weight: 600;
              font-size: 18px;
              line-height: 25px;
              color: #473068;
              text-align: center;
              margin-bottom: 40px;
            "
          >
            Digital Product Passport Public Link
          </div>
          <div class="url-div">
            <v-icon style="color: #501d5f; transform: rotate(120deg)"
              >link</v-icon
            >
            <span
              class="mr-2 ml-2"
              style="border-left: 0.5px solid #501d5f; height: 100px"
            ></span>
            <span v-if="trackingCode" class="url-span" id="toCopy">{{
              trackingCode
            }}</span>
            <span v-else-if="publicLink" class="url-span" id="toCopy">{{
              publicLink
            }}</span>
            <v-icon
              :style="copied ? 'color: #70D0CE;' : 'color: #501D5F;'"
              @click="copying('toCopy')"
              >{{ copied ? "done_all" : "file_copy" }}</v-icon
            >
          </div>
        </div>
      </v-dialog>
    </v-row>
    <div
      id="trace-content-container"
      class="grey lighten-5 section section-container"
    >
      <div id="traceTopBar">
        <div class="top-header-tools">
          <div class="trace-tab-buttons-container">
            <div
              :class="`trace-tab-button ${
                activeTraceTab === 'edit' ? 'trace-tab-button-active' : ''
              }`"
              @click="onClickTraceTab('edit')"
            >
              EDIT
            </div>
            <div
              :class="`trace-tab-button ${
                activeTraceTab === 'preview' ? 'trace-tab-button-active' : ''
              }`"
              @click="onClickTraceTab('preview')"
            >
              PREVIEW
            </div>
            <div
              :class="`trace-tab-button ${
                activeTraceTab === 'analytics' ? 'trace-tab-button-active' : ''
              }`"
              @click="onClickTraceTab('analytics')"
            >
              ANALYTICS
            </div>
          </div>
          <div>
            <v-tooltip :key="`public-link-${publicLinkDialog}`" bottom>
              <template v-slot:activator="{ on, attrs }">
                <md-button
                  v-bind="attrs"
                  v-on="on"
                  class="qr-code-button mr-2"
                  style="color: #eef0fb; background: #473068"
                  @click="openPublicLinkDialog"
                  >PUBLIC LINK</md-button
                >
              </template>
              <div class="hover-container">
                <span
                  >You can get a link to share the Digital Product
                  Passport.</span
                >
              </div>
            </v-tooltip>
            <v-tooltip :key="`qr-link-${qrDialog}`" bottom>
              <template v-slot:activator="{ on, attrs }">
                <md-button
                  v-bind="attrs"
                  v-on="on"
                  class="qr-code-button mr-2"
                  style="color: #eef0fb; background: #473068"
                  @click="openQrCode"
                  >QR CODE</md-button
                >
              </template>
              <div class="hover-container">
                <span
                  >You can get a QR code to share the Digital Product
                  Passport.</span
                >
              </div>
            </v-tooltip>
            <v-tooltip :key="`iframe-link-${iframe}`" bottom>
              <template v-slot:activator="{ on, attrs }">
                <md-button
                  v-bind="attrs"
                  v-on="on"
                  class="qr-code-button mr-2"
                  style="color: #eef0fb; background: #473068"
                  @click="openIframeCode"
                  >IFRAME CODE</md-button
                >
              </template>
              <div class="hover-container">
                <span
                  >You can get a Iframe to embed and share the Digital Product
                  Passport in websites.</span
                >
              </div>
            </v-tooltip>
            <md-button
              class="qr-code-button"
              style="color: #eef0fb; background: #19bebb"
              target="_blank"
              href="https://www.youtube.com/watch?v=RXCag9gGf6U"
              ><span style="display: flex; align-items: center"
                ><v-icon style="color: white">mdi-play</v-icon>HOW DPP
                WORKS</span
              ></md-button
            >
          </div>
        </div>
        <v-divider />
      </div>
      <div v-if="activeTraceTab === 'edit'" style="margin-top: 20px">
        <v-form @submit.prevent="save">
          <v-row align="center">
            <v-col cols="12">
              <v-col
                cols="12"
                class="lable_size"
                style="padding-top: 0px; margin-bottom: 10px"
              >
                <label>Theme</label>
              </v-col>
              <div class="color-selection-container">
                <v-col cols="2" class="color-selection-item">
                  <v-radio-group
                    class="view-selector"
                    v-model="selectedTraceLayout.view"
                  >
                    <v-radio label="Expanded View" value="expanded"></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="2" class="color-selection-item">
                  <color-picker
                    label="Title"
                    :currentColor="selectedTraceLayout.titleColor"
                    description="Title color"
                    @input="(color) => selectColors('titleColor', color)"
                  />
                </v-col>
                <v-col cols="2" class="color-selection-item">
                  <color-picker
                    label="Font"
                    :currentColor="selectedTraceLayout.fontColor"
                    description="Font color"
                    @input="(color) => selectColors('fontColor', color)"
                  />
                </v-col>
                <v-col cols="2" class="color-selection-item">
                  <color-picker
                    label="Icon"
                    :currentColor="selectedTraceLayout.iconColor"
                    description="Icon color"
                    @input="(color) => selectColors('iconColor', color)"
                  />
                </v-col>
                <v-col cols="2" class="color-reset-item">
                  <ui-button
                    class="lighter short reset-button"
                    title="Reset theme"
                    @click="resetTheme"
                  />
                </v-col>
              </div>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col cols="12">
              <div class="color-selection-container" style="margin-bottom: 5px">
                <v-col cols="2" class="color-selection-item">
                  <v-radio-group v-model="selectedTraceLayout.view">
                    <v-radio
                      class="view-selector"
                      label="Compact View"
                      value="compact"
                    ></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="2" class="color-selection-item">
                  <color-picker
                    label="Verified transaction"
                    :currentColor="selectedTraceLayout.verifiedColor"
                    description="Verified transaction color"
                    @input="(color) => selectColors('verifiedColor', color)"
                  />
                </v-col>
                <v-col cols="2" class="color-selection-item">
                  <color-picker
                    label="Unverified transaction"
                    :currentColor="selectedTraceLayout.unverifiedColor"
                    description="Unverified transaction color"
                    @input="(color) => selectColors('unverifiedColor', color)"
                  />
                </v-col>
                <v-col cols="2" class="color-selection-item">
                  <color-picker
                    label="Background"
                    :currentColor="selectedTraceLayout.backgroundColor"
                    description="Background color"
                    @input="(color) => selectColors('backgroundColor', color)"
                  />
                </v-col>
                <v-col cols="2" class="color-selection-item"> </v-col>
              </div>
            </v-col>
          </v-row>
          <v-divider />
          <v-row>
            <v-col>
              <v-row>
                <v-col cols="12">
                  <div class="lable_size" style="padding-top: 0px">
                    <label>Product Name</label>
                    <v-checkbox
                      :ripple="false"
                      :input-value="true"
                      disabled
                    ></v-checkbox>
                  </div>
                  <v-text-field
                    id="product-name-field"
                    class="text-field text-field-custom"
                    label=""
                    v-model="model.productName"
                    :error-messages="productNameError || null"
                    @input="typingProductName"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <div class="lable_size">
                    <label>Geo Location Map</label>
                    <v-checkbox v-model="selectedTraceLayout.geo"></v-checkbox>
                  </div>
                </v-col>
              </v-row>
            </v-col>
            <v-col>
              <div
                class="lable_size"
                style="padding-top: 0px; justify-content: center"
              >
                <label>Product Image</label>
                <v-checkbox
                  class="product-image-checkbox"
                  :ripple="false"
                  :input-value="true"
                  disabled
                ></v-checkbox>
              </div>
              <image-uploader
                :file="file"
                :uploading="uploading"
                @upload="uploadThumbnail"
              />
            </v-col>
            <v-col>
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  justify-content: center;
                  height: 100%;
                "
              >
                <div v-if="brandRegistry?.account" style="width: 100%">
                  <trace-info-message
                    :isSuccess="true"
                    message="Brand is registered."
                  />
                  <div v-if="brandRegistry.account.verified">
                    <trace-info-message
                      :isSuccess="true"
                      message="Brand is verified."
                    />
                  </div>
                  <div style="display: flex" v-else>
                    <trace-info-message
                      :isSuccess="false"
                      message="Brand is not verified."
                    />
                    <div>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            v-bind="attrs"
                            v-on="on"
                            class="ml-auto mt-2"
                            small
                            color="primary"
                            :disabled="
                              verificationRequestedAccounts.includes(
                                brandRegistry.id
                              )
                            "
                            @click="
                              requestVerificationForAccount(brandRegistry.id)
                            "
                          >
                            Request verification
                          </v-btn>
                        </template>
                        <div class="hover-container">
                          <span
                            >Request verification from Seamless Source.</span
                          >
                        </div>
                      </v-tooltip>
                    </div>
                  </div>
                </div>
                <trace-info-message
                  v-else
                  :isSuccess="false"
                  message="Brand is not registered."
                />
                <div style="width: 100%">
                  <trace-info-message
                    v-if="$store.state.inquiryVerificationStatus.verified"
                    :isSuccess="true"
                    message="Style is verified."
                  />
                  <div style="display: flex" v-else>
                    <trace-info-message
                      :isSuccess="false"
                      message="Style is not verified."
                    />
                    <div
                      v-if="$store.state.inquiryVerificationStatus.requestable"
                    >
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            v-bind="attrs"
                            v-on="on"
                            class="ml-auto mt-2"
                            small
                            :color="
                              $store.state.inquiryVerificationStatus.requested
                                ? 'danger'
                                : 'primary'
                            "
                            :disabled="styleVerificationRequested"
                            @click="requestOrRemoveInquiryVerification"
                          >
                            {{
                              $store.state.inquiryVerificationStatus.requested
                                ? "Remove request"
                                : "Request verification"
                            }}
                          </v-btn>
                        </template>
                        <div class="hover-container">
                          <span
                            >The brand own this style is not registered in the
                            platform.
                            {{
                              $store.state.inquiryVerificationStatus.requested
                                ? "Remove verification request sent to Seamless Source"
                                : "Request verification from Seamless Source"
                            }}.</span
                          >
                        </div>
                      </v-tooltip>
                    </div>
                    <div v-else>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            v-bind="attrs"
                            v-on="on"
                            class="ml-auto mt-2"
                            small
                            color="primary"
                            :disabled="styleVerificationRequested"
                            @click="requestVerificationForStyle()"
                          >
                            Request verification
                          </v-btn>
                        </template>
                        <div class="hover-container">
                          <span>Request verification from brand.</span>
                        </div>
                      </v-tooltip>
                    </div>
                  </div>
                </div>
              </div>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12">
              <div class="lable_size">
                <label>Fabric Composition</label>
                <v-checkbox
                  :ripple="false"
                  :input-value="true"
                  disabled
                ></v-checkbox>
              </div>
              <div>
                <v-textarea
                  class="text-field"
                  v-model="model.fabricComposition"
                  maxlength="5000"
                  outlined
                  dense
                >
                </v-textarea>
              </div>
              <hr />
            </v-col>
            <v-row no-gutters>
              <v-col cols="12">
                <div class="lable_size">
                  <label>Care Instructions</label>
                  <v-checkbox
                    :ripple="false"
                    :input-value="true"
                    disabled
                  ></v-checkbox>
                </div>
                <div id="v-select-container" @click="setVSelectMenuProps">
                  <v-select
                    class="select-care-instructions"
                    v-model="model.selectedCareInstruction"
                    :items="getAllCareInstructions"
                    :item-value="`id`"
                    multiple
                    :no-data-text="noDataText"
                    :menu-props="vSelectMenuProps"
                  >
                    <template v-slot:item="{ item, attrs, on }">
                      <v-list-item
                        style="border-left: 0"
                        v-on="on"
                        v-bind="attrs"
                        #default="{ active }"
                      >
                        <v-list-item-action>
                          <v-checkbox
                            :ripple="false"
                            :input-value="active"
                          ></v-checkbox>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title
                            style="text-align: left; margin-left: 0px"
                          >
                            <span v-if="item.icon"
                              ><img
                                class="care-instruction-img"
                                :src="item.icon"
                            /></span>
                            <span
                              :class="
                                item.icon && item.icon != null ? 'ml-4' : 'ml-0'
                              "
                              >{{ item.care_instruction_name }}</span
                            >
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                    <template v-slot:selection="{ item }">
                      <v-chip close @click:close="removeCareInstruction(item)">
                        <span v-if="item.icon"
                          ><img class="care-instruction-img" :src="item.icon"
                        /></span>
                        <span>{{ item.care_instruction_name }}</span>
                      </v-chip>
                    </template>
                  </v-select>
                </div>
              </v-col>
              <!-- Below code commented to uncomment when local / global care instructions handling is ready on next release -->
              <!-- For now we are using uneditable care instructions -->
              <!-- <v-col cols="6">
                            <div class="care_btn_container">
                                <md-button class="view_care_instructions md-raised" @click="AllCareInstructions">View Care Instructions</md-button>
                            </div>
                        </v-col> -->
            </v-row>
            <v-col cols="12">
              <div class="lable_size">
                <label>Sustainability and Circularity</label>
                <v-checkbox
                  :ripple="false"
                  :input-value="true"
                  disabled
                ></v-checkbox>
              </div>
              <div style="margin-top: 15px">
                <vue-editor
                  id="sustainability-text-area"
                  v-model="model.sustainability"
                  placeholder="type here"
                  :editor-toolbar="toolbarOptions"
                />
              </div>
              <hr />
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="6">
              <div class="lable_size">
                <label>Supply Chain</label>
                <v-checkbox
                  v-model="selectedTraceLayout.supplyChain"
                ></v-checkbox>
              </div>
            </v-col>
          </v-row>
          <div v-if="selectedTraceLayout.supplyChain">
            <draggable
              v-bind="dragOptions"
              tag="div"
              :list="model.itemDetails.slice(0, -1)"
              handle=".supply-chain-drag-handle"
              lock-axis="y"
              @end="onDrop"
            >
              <transition-group type="transition" name="flip-list">
                <div
                  v-for="(item, index) in model.itemDetails.slice(0, -1)"
                  :key="`${item.details.id}-drag-${index}`"
                >
                  <div class="supply-chain-item-container">
                    <div class="supply-chain-drag-handle">
                      <v-icon>mdi-drag-vertical</v-icon>
                    </div>
                    <div class="supply-chain-item">
                      <div
                        class="supply-chain-item-content"
                        style="
                          padding: 10px 0 0px;
                          display: flex;
                          align-items: flex-start;
                          justify-content: space-between;
                        "
                      >
                        <div style="display: flex; align-items: flex-start">
                          <div
                            :id="'supply-chain-' + item.details.id + '-icon'"
                            :class="`supply-chain-icon-selector ${
                              iconError && !item.details.icon
                                ? 'supply-chain-icon-error'
                                : ''
                            }`"
                            @click="openIconSelector(item.details.id)"
                          >
                            <div class="add-trace-icon">
                              <v-icon>mdi-upload</v-icon>
                            </div>
                            <trace-services-icon
                              :traceName="item.details.icon"
                              :ellipseSize="40"
                              :iconSize="20"
                            />
                          </div>
                          <v-text-field
                            :id="'supply-chain-' + item.details.id + '-name'"
                            v-model="item.details.name"
                            label="Display Name"
                            style="margin-left: 10px"
                            :error-messages="
                              displayNameError && !item.details.name
                                ? 'Display name is required.'
                                : null
                            "
                            @focus="
                              unfocusIfValueChainAvailable(
                                'supply-chain-' + item.details.id + '-name'
                              )
                            "
                            maxlength="255"
                            outlined
                            dense
                          ></v-text-field>
                        </div>
                        <div
                          class="supply-chain-item-delete"
                          @click="removeSupplyChainItem(index)"
                          style="margin-left: auto"
                        >
                          <v-icon class="supply-chain-delete-icon"
                            >mdi-trash-can-outline</v-icon
                          >
                        </div>
                      </div>
                      <v-row no-gutters>
                        <v-col cols="4" sm="4" md="4" lg="4">
                          <v-autocomplete
                            class="selection-field"
                            clearable
                            v-model="item.details.supplier_id"
                            :items="supplierDetails"
                            item-text="name"
                            item-value="id"
                            label="Company"
                            :menu-props="{ closeOnContentClick: true }"
                            :disabled="!isAccountVerified"
                            outlined
                            @input="
                              onChangeSupplierOrFactory(item.details.id, true)
                            "
                            @mousedown="checkAssignedValueChain"
                          >
                            <template v-slot:item="{ item }">
                              <span class="list-content">{{ item.name }}</span>
                            </template>
                            <template v-slot:selection="{ item }">
                              <span>{{ item.name }}</span>
                            </template>
                            <template v-slot:no-data>
                              <div class="no-suppliers-text" @click="changeTab">
                                No suppliers. Click here to add.
                              </div>
                            </template>
                          </v-autocomplete>
                          <div
                            v-if="!isAccountVerified"
                            class="not-verified-text"
                          >
                            Your company profile needs to be verified to select
                            a company, factory or store.
                          </div>
                        </v-col>
                        <v-col cols="3" sm="3" md="3" lg="3" class="ml-4">
                          <v-autocomplete
                            class="selection-field"
                            clearable
                            v-model="item.details.factory_id"
                            :items="[
                              ...(item.details.supplier_id &&
                              supplierDetails.find(
                                (supplier) =>
                                  supplier.id == item.details.supplier_id
                              )?.account
                                ? [
                                    {
                                      id: useCompanyAsFactoryId,
                                      name: `Use the company as the ${
                                        supplierDetails.find(
                                          (supplier) =>
                                            supplier.id ==
                                            item.details.supplier_id
                                        ).type_details.short_code === 'brand'
                                          ? 'store'
                                          : 'factory'
                                      }`,
                                    },
                                  ]
                                : []),
                              ...(supplierDetails.find(
                                (supplier) =>
                                  supplier.id == item.details.supplier_id
                              )?.factories || []),
                            ]"
                            @input="onChangeSupplierOrFactory(item.details.id)"
                            item-text="name"
                            item-value="id"
                            :label="
                              !item.details.supplier_id
                                ? 'Factories / Stores'
                                : supplierDetails.find(
                                    (supplier) =>
                                      supplier.id == item.details.supplier_id
                                  )?.type_details.short_code === 'brand'
                                ? 'Stores'
                                : 'Factories'
                            "
                            :disabled="!isAccountVerified"
                            :menu-props="{
                              closeOnContentClick: true,
                            }"
                            outlined
                          >
                            <template
                              v-if="
                                item.details.supplier_id &&
                                supplierDetails.find(
                                  (supplier) =>
                                    supplier.id == item.details.supplier_id
                                )?.type_details.short_code !== 'brand'
                              "
                              v-slot:prepend-item
                            >
                              <div
                                @click="
                                  unableToFindFactory(
                                    supplierDetails.find(
                                      (supplier) =>
                                        supplier.id == item.details.supplier_id
                                    )
                                  )
                                "
                                class="unable-to-find-factory"
                              >
                                I'm unable to find the factory
                              </div>
                            </template>
                            <template v-slot:item="{ item }">
                              <span class="list-content">{{ item.name }}</span>
                            </template>
                            <template v-slot:selection="{ item }">
                              <span>{{ item.name }}</span>
                            </template>
                          </v-autocomplete>
                          <div
                            v-if="
                              item.details.supplier?.account &&
                              (!item.details.supplier?.account?.city ||
                                !item.details.supplier?.account?.country)
                            "
                            class="no-location-warning"
                          >
                            Selected company does not have a location to display
                            yet
                          </div>
                        </v-col>
                        <v-col class="ml-1" style="display: flex">
                          <div
                            class="geo-image-selector-button ml-4 mr-4"
                            @click.prevent="
                              handleGeoWindow('trace', item.details.id)
                            "
                          >
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <div v-bind="attrs" v-on="on">
                                  <geo-tagging-icon />
                                </div>
                              </template>
                              <div class="hover-container">
                                <span>Add a geo tag image</span>
                              </div>
                            </v-tooltip>
                          </div>
                          <div
                            v-if="item.details.factory_id"
                            class="chip-container ml-1"
                          >
                            <trace-info-message
                              v-if="
                                !supplierDetails.find(
                                  (supplier) =>
                                    supplier.id == item.details.supplier_id
                                )?.account
                              "
                              :isSuccess="false"
                              message="Company is not registered."
                            />
                            <trace-info-message
                              v-else-if="
                                supplierDetails.find(
                                  (supplier) =>
                                    supplier.id == item.details.supplier_id
                                )?.account.verified
                              "
                              :isSuccess="true"
                              message="Company is verified."
                            />
                            <div v-else>
                              <trace-info-message
                                :isSuccess="false"
                                message="Company is not verified."
                              />
                              <div>
                                <v-tooltip bottom>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      v-bind="attrs"
                                      v-on="on"
                                      class="ml-auto mt-2"
                                      small
                                      color="primary"
                                      :disabled="
                                        verificationRequestedAccounts.includes(
                                          item.details.supplier_id
                                        )
                                      "
                                      @click="
                                        requestVerificationForAccount(
                                          item.supplier_id
                                        )
                                      "
                                    >
                                      Request verification
                                    </v-btn>
                                  </template>
                                  <div class="hover-container">
                                    <span
                                      >Request verification from Seamless
                                      Source.</span
                                    >
                                  </div>
                                </v-tooltip>
                              </div>
                            </div>
                            <div
                              v-if="
                                item.details.factory_id !==
                                useCompanyAsFactoryId
                              "
                            >
                              <trace-info-message
                                v-if="
                                  item.verified_by_brand ||
                                  item.verified_by_factory
                                "
                                :isSuccess="true"
                                message="Transaction is verified."
                              />
                              <div style="display: flex" v-else>
                                <trace-info-message
                                  :isSuccess="false"
                                  message="Transaction is not verified."
                                />
                                <div>
                                  <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn
                                        v-bind="attrs"
                                        v-on="on"
                                        class="ml-auto mt-2"
                                        small
                                        color="primary"
                                        :disabled="
                                          verificationRequestedTransactions.includes(
                                            item?.details?.id
                                          )
                                        "
                                        @click="
                                          requestVerificationForTransaction(
                                            item?.details?.id
                                          )
                                        "
                                      >
                                        Request verification
                                      </v-btn>
                                    </template>
                                    <div class="hover-container">
                                      <span
                                        >Request verification from brand or
                                        supplier.</span
                                      >
                                    </div>
                                  </v-tooltip>
                                </div>
                              </div>
                            </div>
                            <div v-else>
                              <trace-info-message
                                :isSuccess="false"
                                :message="`The company is used as the ${
                                  supplierDetails.find(
                                    (supplier) =>
                                      supplier.id == item.details.supplier_id
                                  )?.type_details.short_code === 'brand'
                                    ? 'store'
                                    : 'factory'
                                }`"
                              />
                            </div>
                          </div>
                        </v-col>
                      </v-row>
                      <hr />
                    </div>
                  </div>
                </div>
              </transition-group>
            </draggable>
            <div class="supply-chain-add-icon" @click="addSupplyChainItem()">
              <v-icon>mdi-plus-circle-outline </v-icon>
            </div>
            <div v-if="model.itemDetails.length">
              <div style="display: flex; align-items: center">
                <trace-services-icon
                  traceName="DeliveryIcon"
                  :ellipseSize="40"
                  :iconSize="20"
                />
                <div style="margin-left: 10px; font-size: 20px; color: #582963">
                  <label>Delivery</label>
                </div>
              </div>
              <v-row no-gutters>
                <v-col cols="12">
                  <div>
                    <v-row class="radio_group_size">
                      <v-radio-group
                        :value="
                          model.itemDetails[model.itemDetails.length - 1]
                            .details.delivery_method
                        "
                        row
                      >
                        <v-radio
                          v-for="method in deliveryMethods"
                          :key="method.id"
                          :label="method.name"
                          :value="method.id"
                          @click.stop="resetDelivery(method.id)"
                        ></v-radio>
                      </v-radio-group>
                    </v-row>
                  </div>
                </v-col>
              </v-row>
              <hr />
            </div>
          </div>
          <popup-window
            :active="isAlertOpen"
            :has-close="false"
            :full-screen="false"
            @popup-close="isAlertOpen = false"
            @popup-back="isAlertOpen = false"
          >
            <p style="padding-top: 10px">
              To edit supply chain and circularity details, the added pre-defined value
              chain needs to be removed.<br /><br />Are you sure you want to
              remove <b>{{ assignedValueChain?.name }}</b> value chain from this
              style?
            </p>
            <div style="padding-bottom: 10px">
              <md-button
                id="publishButton"
                class="md-raised"
                style="
                  background-color: transparent;
                  color: #582963;
                  border: 1px solid #582963;
                "
                @click="isAlertOpen = false"
              >
                Cancel
              </md-button>
              <md-button
                id="publishButton"
                class="md-raised"
                :style="`background-color: ${
                  !submitDisabled ? '#582963' : '#cccccc'
                }; color: ${!submitDisabled ? '#EEF0FB' : '#666666'};`"
                @click="removeValueChain"
              >
                Yes, Remove
              </md-button>
            </div>
          </popup-window>
          <icon-selector
            :active="!!openedIconSelector"
            @popup-close="openedIconSelector = null"
            @icon-selected="iconSelected"
          />
          <v-row no-gutters>
            <v-col cols="6">
              <div class="lable_size">
                <label>Donation, Repair, Upcycle, Recycle and Resale</label>
                <v-checkbox
                  v-model="selectedTraceLayout.repairRecycleAndResale"
                ></v-checkbox>
              </div>
            </v-col>
          </v-row>
          <div v-if="selectedTraceLayout.repairRecycleAndResale">
            <div
              v-for="(item, key) in [
                { key: 'donations', label: 'Donation' },
                { key: 'repairs', label: 'Repair' },
                { key: 'upcycles', label: 'Upcycle' },
                { key: 'recycles', label: 'Recycle' },
                { key: 'resales', label: 'Resale' },
              ]"
              :key="key"
            >
              <v-row no-gutters>
                <v-col cols="3">
                  <div class="lable_size" style="font-weight: unset">
                    <label>{{ item.label }}</label>
                  </div>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="8" sm="8" md="8" lg="8">
                  <v-row align="center">
                    <v-col cols="12">
                      <v-autocomplete
                        class="selection-field"
                        :search-input.sync="search"
                        clearable
                        multiple
                        v-model="model[item.key]"
                        :items="[
                          ...(model[item.key] && Array.isArray(model[item.key])
                            ? model[item.key]
                            : []),
                          ...globalSuppliers.filter(
                            (supplier) =>
                              !model[item.key].find(
                                (selection) => selection.id === supplier.id
                              )
                          ),
                        ]"
                        item-text="name"
                        item-value="id"
                        label="Companies"
                        :disabled="!isAccountVerified"
                        outlined
                        @mousedown="checkAssignedValueChain"
                        return-object
                      >
                        <template v-slot:item="{ item }">
                          <span class="list-content">{{ item.name }}</span>
                        </template>
                        <template v-slot:selection="{ item, index }">
                          <span
                            :key="'selected' + index"
                            class="list-content"
                            style="width: 100%"
                            >{{ item.name }}</span
                          >
                        </template>
                        <template v-slot:append-item>
                          <infinite-loading
                            ref="InfiniteLoading"
                            @infinite="fetchMoreSuppliers"
                            :key="`infinite-toggle-${searchName}`"
                          >
                            <div slot="no-more" v-if="globalSuppliers.length">
                              No more data
                            </div>
                            <div slot="no-more" v-if="!globalSuppliers.length">
                              No data available
                            </div>
                            <div
                              slot="no-results"
                              v-if="globalSuppliers.length"
                            >
                              No more data
                            </div>
                            <div
                              slot="no-results"
                              v-if="!globalSuppliers.length"
                            >
                              No data available
                            </div>
                          </infinite-loading>
                        </template>
                      </v-autocomplete>
                      <div v-if="!isAccountVerified" class="not-verified-text">
                        Your company profile needs to be verified to select a
                        supplier.
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <hr />
            </div>
          </div>
          <v-row no-gutters>
            <v-col cols="6">
              <div class="lable_size">
                <label>Bill of Materials (Component Traceability)</label>
                <v-checkbox v-model="selectedTraceLayout.bom"></v-checkbox>
              </div>
            </v-col>
            <div v-if="selectedTraceLayout.bom" class="bom-items-flexbox">
              <div
                v-for="bomItem in allBomRecords"
                :key="bomItem.id"
                class="bom-item-wrapper"
              >
                <v-checkbox
                  :ripple="false"
                  :input-value="model.selectedBomRecords.includes(bomItem.id)"
                  @click="clickOnBomRecord(bomItem.id)"
                ></v-checkbox>
                <bom-item-trace
                  :bomItem="bomItem"
                  :expandWithPopup="true"
                  :entireTraceUnverified="markWholeTraceAsUnverified"
                  :customHeight="40"
                  @geo-tag-clicked="handleGeoWindowAction"
                  :showGeoTagIcon="true"
                ></bom-item-trace>
              </div>
            </div>
          </v-row>
          <v-row no-gutters>
            <v-col cols="6" style="display: flex; flex-direction: column">
              <div class="lable_size">
                <label>Customer Reviews</label>
                <v-checkbox v-model="selectedTraceLayout.reviews"></v-checkbox>
              </div>
              <p style="align-self: start">
                Submitted reviews will be displayed in the Analytics tab
              </p>
            </v-col>
          </v-row>
          <md-card-actions>
            <md-button
              id="publishButton"
              class="md-raised"
              :style="`background-color: ${
                !submitDisabled ? '#582963' : '#cccccc'
              }; color: ${!submitDisabled ? '#EEF0FB' : '#666666'};`"
              @click="publish(!isPublished ? 'publish' : 'approve')"
            >
              {{ !isPublished ? "Publish" : "Publish Changes" }}
            </md-button>

            <md-button
              v-if="!isPublished && !needTraceApproval"
              id="saveButton"
              class="md-raised"
              :disabled="submitDisabled"
              :style="`background-color: ${
                !submitDisabled ? '#582963' : '#cccccc'
              }; color: ${!submitDisabled ? '#EEF0FB' : '#666666'};`"
              @click="submit('submit')"
            >
              {{ submitDisabled ? "Saving.." : "Save" }}
            </md-button>

            <md-button
              v-else
              id="submitChanges"
              :disabled="submitDisabled"
              class="md-raised"
              :style="`background-color: ${
                !submitDisabled ? '#582963' : '#cccccc'
              }; color: ${!submitDisabled ? '#EEF0FB' : '#666666'};`"
              @click="submit('update')"
            >
              {{ submitDisabled ? "Saving.." : "Save Changes" }}
            </md-button>
          </md-card-actions>
        </v-form>
        <v-form ref="addNoteForm">
          <v-row no-gutters>
            <v-col cols="12">
              <div class="lable_size">
                <label>Internal notes</label>
              </div>
              <div style="padding-top: 20px">
                <v-textarea
                  v-model="note"
                  outlined
                  dense
                  placeholder="Add a note..."
                  class="note-field smaller-note-field note-field-custom"
                  :rules="rules.noteRules"
                  counter="255"
                  rows="3"
                  auto-grow
                ></v-textarea>
              </div>
            </v-col>
          </v-row>
          <div class="note-button-container">
            <md-button class="md-raised button-custom" @click="saveNote">
              Save Note
            </md-button>
          </div>
        </v-form>
      </div>
      <div
        v-if="activeTraceTab === 'preview'"
        class="grey lighten-5 section"
        style="padding: 20px; margin-top: 20px"
      >
        <v-row no-gutters>
          <v-col>
            <TraceMobile
              v-if="isSmallScreen"
              :customerWindow="true"
              :publicLink="''"
              :traceLink="''"
              :linkClick="false"
              :showToggle="false"
              :qr="''"
              :trackingCode="null"
              :preview="true"
              :forceHideReviewComponent="!this.selectedTraceLayout.reviews"
              :previewTraceDetails="previewTraceDetails"
            />
            <Trace
              v-else
              :customerWindow="true"
              :publicLink="''"
              :traceLink="''"
              :linkClick="false"
              :showToggle="false"
              :qr="''"
              :trackingCode="null"
              :preview="true"
              :forceHideReviewComponent="!this.selectedTraceLayout.reviews"
              :previewTraceDetails="previewTraceDetails"
            />
          </v-col>
        </v-row>
      </div>
      <div
        v-if="activeTraceTab === 'analytics'"
        class="grey lighten-5 section"
        style="padding: 20px; margin-top: 20px"
      >
        <div class="grey lighten-5 section feedback-container">
          <div class="more-info-container">
            <div v-if="!isSmallScreen" class="icon-container">
              <ellipse-icon
                :width="'59px'"
                :height="'59px'"
                border="2px"
                variant="success"
              >
                <customer-feedback-icon />
              </ellipse-icon>
              <span class="more-info-title title-underline"
                >Customer Feedback</span
              >
            </div>
            <div v-else class="icon-container title-underline">
              <customer-feedback-icon fill="#70D0CE" :width="24" :height="24" />
              <span class="more-info-title">Customer Feedback</span>
            </div>
            <review
              :isMobile="isSmallScreen"
              :styleNumber="styleNumber"
              :inquiryId="inquiryId"
              :isAddReviewVisible="false"
              :isAuthorizedToChangeReviewStatus="true"
              :colorCodes="{
                titleColor: '#473068',
                iconColor: '#19BEBB',
                backgroundColor: '#ffffff',
              }"
            />
          </div>
        </div>
      </div>
    </div>
    <geo-selector
      :active="geoSelectionWindow.active"
      :selected="getSelectedGeoImages"
      :geoImages="getUnselectedGeoImages"
      :selectedOptions="[
        {
          text: 'Download',
          color: '#442D65',
          onClick: (image) => downloadGeoImage(image),
        },
        {
          text: 'Remove',
          color: '#EA7A66',
          onClick: (image) => unselectGeoImage(image),
        },
      ]"
      @close="closeGeoWindow"
      @select="selectGeoImage"
    />
    <div>
      <v-dialog persistent v-model="showCareInstructions" width="80%">
        <v-card width="100%">
          <NonConsumerCareInstructionHandleForm
            @update-instructions="updateCareInstructions"
            :showCareInstructions="showCareInstructions"
            @close="showCareInstructions = false"
          />
        </v-card>
      </v-dialog>
      <popup-window
        :active="unableToFindFactoryPopup.active"
        :hasClose="false"
        noMargin
        @popup-close="unableToFindFactoryPopup.active = false"
        @popup-back="unableToFindFactoryPopup.active = false"
      >
        <factory-form
          ref="factoryCreationFormTrace"
          :loading="unableToFindFactoryPopup.creating"
          :isRequest="
            unableToFindFactoryPopup.isSupplierRegistered &&
            !unableToFindFactoryPopup.isMyCompanyTheSupplier
          "
          @close="closeFactoryForm"
          @create="
            (formData) =>
              unableToFindFactoryPopup.isSupplierRegistered &&
              !unableToFindFactoryPopup.isMyCompanyTheSupplier
                ? requestFactory(formData)
                : createFactory(formData)
          "
        />
      </popup-window>
    </div>
  </div>
</template>

<script>
/* eslint-disable @typescript-eslint/camelcase */
import restAdapter from "../../restAdapter";
import notification from "../../notification";
import NonConsumerCareInstructionHandleForm from "../Inquiry/NonConsumerCareInstructionHandleForm.vue";
import QrCode from "./QrCode.vue";
import Review from "./Review.vue";
import { setLeaveAlert, resetLeaveAlert } from "../../utils/confirmBox";
import store from "@/store";
import Trace from "./Trace.vue";
import TraceMobile from "./TraceMobile.vue";
import BomItemTrace from "./BomItemTrace.vue";
import * as conf from "../../../src/conf.yml";
import ColorPicker from "../base/ColorPicker.vue";
import GeoSelector from "./GeoSelector.vue";
import FactoryForm from "../FactoryForm.vue";
import auth from "@/auth";
import debounce from "lodash/debounce";
import { VueEditor, Quill } from "vue2-editor";
import InfiniteLoading from "vue-infinite-loading";
import TraceInfoMessage from "./TraceInfoMessage.vue";
import ImageUploader from "./ImageUploader.vue";
import draggable from "vuedraggable";
import IconSelector from "./IconSelector.vue";

export default {
  name: "NonConsumerTraceForm",
  components: {
    NonConsumerCareInstructionHandleForm,
    QrCode,
    Review,
    Trace,
    TraceMobile,
    BomItemTrace,
    ColorPicker,
    GeoSelector,
    FactoryForm,
    InfiniteLoading,
    VueEditor,
    TraceInfoMessage,
    ImageUploader,
    IconSelector,
    draggable,
  },
  props: {
    traceLayout: {
      type: Object,
      default: () => ({
        bom: false,
        supplyChain: true,
        reviews: true,
        geo: false,
        view: "expanded",
        repairRecycleAndResale: false,
      }),
    },
    brandRegistry: {
      type: Object,
      default: null,
    },
    assignedValueChain: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    inquiryId: null,
    defaultImage: "",
    useCompanyAsFactoryId: "useCompanyAsFactory",
    baseUrl: "",
    note: "",
    loading: true,
    uploading: false,
    copied: false,
    copiedFrameCode: false,
    origin: "",
    submitDisabled: false,
    reviewBoxClose: false,
    verificationRequestedAccounts: [],
    styleVerificationRequested: false,
    verificationRequestedTransactions: [],
    model: {
      itemDetails: [],
      productName: "",
      fabricComposition: "",
      selectedCareInstruction: [],
      sustainability: "",
      selectedBomRecords: [],
      bomGeoImages: [],
      traceGeoImages: [],
      repairs: [],
      recycles: [],
      resales: [],
      donations: [],
      upcycles: [],
    },
    rules: {
      noteRules: [
        (value) => !!value || "Note is required",
        (value) =>
          !value ||
          value.length <= 255 ||
          "Note must be less than 255 characters",
      ],
    },
    ignoreFormEdit: false,
    supplierDetails: [],
    globalSuppliers: [],
    deliveryMethods: [],
    styleNumber: null,
    isPublished: false,
    needTraceApproval: false,
    showCareInstructions: false,
    unableToFindFactoryPopup: {
      active: false,
      supplier: null,
      creating: false,
      isSupplierRegistered: false,
      isMyCompanyTheSupplier: false,
    },
    allCareInstructions: [],
    geoImages: [],
    allBomRecords: [],
    careInstructions: [],
    noDataText: "please wait",
    productNameError: false,
    displayNameError: false,
    iconError: false,
    publicLink: "",
    qr: "",
    globalSuppliersCurrentPage: 1,
    loadingSuppliers: false,
    trackingCode: "",
    publicLinkDialog: false,
    qrDialog: false,
    iframe: false,
    activeTraceTab: "edit",
    brandLogo: null,
    brandLink: null,
    markWholeTraceAsUnverified: false,
    selectedTraceLayout: {
      bom: false,
      supplyChain: true,
      reviews: true,
      geo: false,
      view: "expanded",
      repairRecycleAndResale: false,
      titleColor: "#473068",
      fontColor: "#473068",
      iconColor: "#70d0ce",
      verifiedColor: "#473068",
      unverifiedColor: "#ea7a66",
      backgroundColor: "#ffffff",
    },
    vSelectMenuProps: {
      bottom: true,
      offsetY: true,
    },
    geoSelectionWindow: {
      type: null,
      id: null,
      active: false,
    },
    windowSize: window.innerWidth,
    isDistanceRecorded: false,
    initialDistance: 0,
    isAlertOpen: false,
    isConfirmed: false,
    toolbarOptions: [
      // [{ header: [1, 2, 3, 4, false] }],
      ["bold", "italic", "underline", "strike"], // toggled buttons
      [{ color: [] }, { background: [] }],
      // [
      //   { align: "" },
      //   { align: "center" },
      //   { align: "right" },
      //   { align: "justify" },
      // ],
      // [{ direction: "rtl" }],
      [{ list: "ordered" }, { list: "bullet" }],
      // [{ indent: "-1" }, { indent: "+1" }],
      ["link"],
    ],
    file: {
      url: require(`../../assets/shirt.png`),
      title: "Sample-Image.png",
      size: 0,
      isDefault: true,
    },
    maxFileSize: 50 * 1024 * 1024,
    openedIconSelector: null,
  }),

  filters: {
    truncate: function (text, stop, clamp) {
      return text.slice(0, stop) + (stop < text.length ? clamp || "..." : "");
    },
  },
  watch: {
    model: {
      handler: function () {
        this.ignoreFormEdit = setLeaveAlert(this.ignoreFormEdit);
      },
      deep: true,
    },
    "$store.state.inquirySuppliersUpdated": {
      handler: function () {
        this.getSuppliers();
        this.updateValueChainData();
      },
      deep: true,
    },
    "$store.state.bomRefreshToggle": {
      handler: function () {
        restAdapter
          .get(`/api/inquiry/${this.inquiryId}/trace`)
          .then((response) => {
            this.allBomRecords = response.data.bom_records;
            this.model.selectedBomRecords =
              this.model.selectedBomRecords.filter((bomId) =>
                this.allBomRecords.find((bomItem) => bomItem.id === bomId)
              );
          });
      },
      deep: true,
    },
    traceLayout: {
      handler: function (value) {
        this.selectedTraceLayout = {
          bom: value.bom,
          supplyChain: value.supply_chain,
          reviews: value.reviews,
          geo: value.geo,
          view: value.view,
          repairRecycleAndResale: value.repair_recycle_and_resale,
          titleColor: value.title_color,
          fontColor: value.font_color,
          iconColor: value.icon_color,
          verifiedColor: value.verified_color,
          unverifiedColor: value.unverified_color,
          backgroundColor: value.background_color,
        };
      },
      immediate: true,
    },
  },

  created() {
    window.addEventListener("resize", this.screenSizeHandler);
    window.addEventListener("scroll", this.screenScrollHandler);
  },

  destroyed() {
    window.removeEventListener("resize", this.screenSizeHandler);
  },

  computed: {
    search: {
      get() {
        return this.searchName;
      },
      set(searchInput) {
        if (this.searchName !== searchInput) {
          this.searchName = searchInput;
          this.this.loadingSuppliers = true;
          this.debounceSearch();
        }
      },
    },
    isSmallScreen() {
      return this.windowSize <= 750;
    },
    isAccountVerified() {
      return auth.user.account?.verified;
    },
    getAllCareInstructions() {
      return this.allCareInstructions.filter(
        (item) =>
          this.careInstructions.some(
            (instruction) => item.id == instruction.id
          ) ||
          this.model.selectedCareInstruction.some(
            (selected) => item.id == selected
          )
      );
    },
    dragOptions() {
      return {
        animation: 200,
        group: "supply-chain",
        disabled: false,
        ghostClass: "ghost",
      };
    },
    previewTraceDetails() {
      const selectedInstructions =
        this.getAllCareInstructions && this.getAllCareInstructions.length
          ? this.model.selectedCareInstruction
          : [];
      const allInstructions = this.getAllCareInstructions || [];

      return {
        color_codes: {
          titleColor: this.selectedTraceLayout.titleColor,
          fontColor: this.selectedTraceLayout.fontColor,
          iconColor: this.selectedTraceLayout.iconColor,
          verifiedColor: this.selectedTraceLayout.verifiedColor,
          unverifiedColor: this.selectedTraceLayout.unverifiedColor,
          backgroundColor: this.selectedTraceLayout.backgroundColor,
        },
        reviews: this.selectedTraceLayout.reviews,
        repairs: this.processRepairRecycleAndResaleForPreview(
          this.model.repairs
        ),
        recycles: this.processRepairRecycleAndResaleForPreview(
          this.model.recycles
        ),
        resales: this.processRepairRecycleAndResaleForPreview(
          this.model.resales
        ),
        upcycles: this.processRepairRecycleAndResaleForPreview(
          this.model.upcycles
        ),
        donations: this.processRepairRecycleAndResaleForPreview(
          this.model.donations
        ),
        bom_records: this.selectedTraceLayout.bom
          ? this.model.selectedBomRecords.map((bomId) =>
              this.allBomRecords.find((bomItem) => bomItem.id === bomId)
            )
          : [],
        bom_geo_images:
          this.selectedTraceLayout.geo && this.selectedTraceLayout.bom
            ? this.model.bomGeoImages
            : [],
        trace_geo_images:
          this.selectedTraceLayout.geo && this.selectedTraceLayout.supplyChain
            ? this.model.traceGeoImages
            : [],
        details:
          this.selectedTraceLayout.supplyChain && this.model.itemDetails
            ? this.model.itemDetails.map((item) => {
                return {
                  ...item.details,
                  factory_id:
                    item.details.factory_id === this.useCompanyAsFactoryId
                      ? null
                      : item.details.factory_id,
                  used_supplier_as_factory:
                    item.details.factory_id === this.useCompanyAsFactoryId,
                  field_name: item.details.name,
                  verified:
                    (item.details.factory &&
                      item.details.supplier &&
                      item.details.supplier.account &&
                      item.details.supplier.account.verified &&
                      (item.details.verified_by_factory ||
                        item.details.verified_by_brand)) ||
                    (item.details.delivery_method &&
                      !this.markWholeTraceAsUnverified)
                      ? true
                      : false,
                };
              }) ?? []
            : [],
        product_name: this.model.productName,
        delivery_methods: this.deliveryMethods,
        fabric_composition: this.model.fabricComposition,
        care_instructions: selectedInstructions.map((item) => {
          return allInstructions.find((instruction) => {
            return instruction.id == item;
          });
        }),
        sustainability_and_circularity: this.model.sustainability,
        trace_view: this.selectedTraceLayout.view,
        brand_logo: this.brandLogo,
        brand_link: this.brandLink,
        mark_whole_trace_as_unverified: this.markWholeTraceAsUnverified,
        default_image: this.defaultImage,
      };
    },
    getSelectedGeoImages() {
      if (this.geoSelectionWindow.type === "bom") {
        return this.model.bomGeoImages
          .filter((image) => image.bom_item_id === this.geoSelectionWindow.id)
          .map((image) => ({
            id: image.id,
            url: `${this.baseUrl}/api/geo/preview/${image.geo_image.upload_name}`,
            loading: image.loading || false,
          }));
      } else {
        return this.model.traceGeoImages
          .filter(
            (image) => image.inquiry_trace_id === this.geoSelectionWindow.id
          )
          .map((image) => ({
            id: image.geo_image.id,
            url: `${this.baseUrl}/api/geo/preview/${image.geo_image.upload_name}`,
            loading: image.loading || false,
          }));
      }
    },
    getUnselectedGeoImages() {
      if (this.geoSelectionWindow.type === "bom") {
        return this.geoImages.filter(
          (image) =>
            !this.model.bomGeoImages.some(
              (geoImage) =>
                geoImage.geo_image_id === image.id &&
                geoImage.bom_item_id === this.geoSelectionWindow.id
            )
        );
      } else {
        return this.geoImages.filter(
          (image) =>
            !this.model.traceGeoImages.some(
              (geoImage) =>
                geoImage.geo_image_id === image.id &&
                geoImage.inquiry_trace_id === this.geoSelectionWindow.id
            )
        );
      }
    },
  },

  mounted() {
    this.baseUrl = conf.default.server.host;
    this.inquiryId = this.$route.params.id;
    this.initialize();
    this.getGeoGallery();
    this.origin = window.location.origin;
    this.readNonConsumerData();
    this.getSuppliers();
    this.getGlobalSuppliers();
  },

  methods: {
    resetDelivery(value) {
      this.model.itemDetails[
        this.model.itemDetails.length - 1
      ].details.delivery_method =
        this.model.itemDetails[this.model.itemDetails.length - 1].details
          .delivery_method === value
          ? null
          : value;
    },
    changeTab() {
      this.$emit("change-tab", "suppliers");
    },
    openIconSelector(id) {
      if (this.checkAssignedValueChain()) {
        return;
      }
      this.openedIconSelector = id;
    },
    unfocusIfValueChainAvailable(elementId) {
      if (this.checkAssignedValueChain()) {
        document.getElementById(elementId).blur();
        return;
      }
    },
    iconSelected(icon) {
      this.model.itemDetails = this.model.itemDetails.map((item) => {
        if (item.details.id === this.openedIconSelector) {
          return {
            ...item,
            details: {
              ...item.details,
              icon: icon.name,
            },
            icon: icon.name,
          };
        }
        return item;
      });
    },
    removeSupplyChainItem(index) {
      if (this.checkAssignedValueChain()) {
        return;
      }
      const confirmDeletion = confirm(
        "Are you sure you want to remove this item?"
      );
      if (confirmDeletion) {
        this.model.itemDetails.splice(index, 1);
      }
    },
    addSupplyChainItem() {
      if (this.checkAssignedValueChain()) {
        return;
      }
      const id = `new-${Math.random().toString(36).substr(2, 9)}`;
      this.model.itemDetails.splice(this.model.itemDetails.length - 1, 0, {
        id: id,
        details: {
          id,
          name: "",
          icon: "",
          order: this.model.itemDetails.length,
          supplier_id: null,
          factory_id: null,
          delivery_method: null,
        },
        name: "",
        icon: "",
        order: this.model.itemDetails.length,
      });
    },
    onDrop(dropResult) {
      const removedIndex = dropResult.oldIndex;
      const addedIndex = dropResult.newIndex;

      if (removedIndex !== null && addedIndex !== null) {
        const itemToMove = this.model.itemDetails.splice(removedIndex, 1)[0];
        this.model.itemDetails.splice(addedIndex, 0, itemToMove);
      }
    },
    unableToFindFactory(supplier) {
      this.unableToFindFactoryPopup.isSupplierRegistered = false;
      this.unableToFindFactoryPopup.isMyCompanyTheSupplier = false;
      if (supplier.account) {
        this.unableToFindFactoryPopup.isSupplierRegistered = true;
        if (supplier.id === auth.user.account.organization_registry_id) {
          this.unableToFindFactoryPopup.isMyCompanyTheSupplier = true;
        }
      }
      this.unableToFindFactoryPopup.supplier = supplier;
      this.unableToFindFactoryPopup.active = true;
    },
    closeFactoryForm() {
      this.unableToFindFactoryPopup.active = false;
    },
    createFactory(factory) {
      this.unableToFindFactoryPopup.creating = true;
      restAdapter
        .post(`/api/factories`, {
          name: factory.name,
          established_city: factory.establishedCity,
          established_country: factory.establishedCountry,
          global_organization_id: this.unableToFindFactoryPopup.supplier.id,
        })
        .then((response) => {
          this.supplierDetails = this.supplierDetails.map((supplier) => {
            if (supplier.id === this.unableToFindFactoryPopup.supplier.id) {
              return {
                ...supplier,
                factories: [
                  ...supplier.factories,
                  {
                    ...response.data.factory,
                  },
                ],
              };
            }
            return supplier;
          });
          this.unableToFindFactoryPopup.creating = false;
          this.$refs.factoryCreationFormTrace.close();
          store.commit(
            "SET_UPDATE_INQUIRY_SUPPLIER",
            !this.$store.state.inquirySuppliersUpdated
          );
          notification.success("Factory added successfully");
        })
        .catch((error) => {
          this.unableToFindFactoryPopup.creating = false;
          notification.error("Something went wrong.");
        });
    },
    requestFactory(factory) {
      this.unableToFindFactoryPopup.creating = true;
      restAdapter
        .post(`/api/factory_requests`, {
          name: factory.name,
          city: factory.establishedCity,
          country: factory.establishedCountry,
          organization_id: this.unableToFindFactoryPopup.supplier.id,
        })
        .then((response) => {
          this.unableToFindFactoryPopup.creating = false;
          this.$refs.factoryCreationFormTrace.close();
          this.closeFactoryForm();
          notification.success("New factory requested successfully");
        })
        .catch((error) => {
          this.unableToFindFactoryPopup.creating = false;
          this.$refs.factoryCreationFormTrace.close();
          this.closeFactoryForm();
          notification.error("Something went wrong.");
        });
    },
    handleGeoWindow(type, id) {
      this.geoSelectionWindow = {
        type,
        id,
        active:
          type === this.geoSelectionWindow.type &&
          id === this.geoSelectionWindow.id
            ? !this.geoSelectionWindow.active
            : true,
      };
    },
    handleGeoWindowAction(itemId) {
      this.handleGeoWindow("bom", itemId);
    },
    closeGeoWindow() {
      this.geoSelectionWindow = {
        type: null,
        id: null,
        active: false,
      };
    },
    selectGeoImage(image) {
      if (this.geoSelectionWindow.type === "trace") {
        this.model.traceGeoImages.push({
          ...image,
          geo_image_id: image.id,
          geo_image: image,
          inquiry_trace_id: this.geoSelectionWindow.id,
        });
        this.geoImages = this.geoImages.map((geoImage) => {
          if (geoImage.id === image.id) {
            return {
              ...geoImage,
              loading: false,
            };
          }
          return geoImage;
        });
      } else {
        this.geoImages = this.geoImages.map((geoImage) => {
          if (geoImage.id === image.id) {
            return {
              ...geoImage,
              loading: true,
            };
          }
          return geoImage;
        });
        restAdapter
          .post(`/api/inquiries/${this.inquiryId}/bom/geo`, {
            geo_image_id: image.id,
            bom_item_id: this.geoSelectionWindow.id,
          })
          .then((response) => {
            this.model.bomGeoImages.push({
              ...response.data.geo_image,
            });
            notification.success("Geo image added successfully");
          })
          .catch((error) => {
            notification.error("Something went wrong.");
          })
          .finally(() => {
            this.geoImages = this.geoImages.map((geoImage) => {
              if (geoImage.id === image.id) {
                return {
                  ...geoImage,
                  loading: false,
                };
              }
              return geoImage;
            });
          });
      }
    },
    unselectGeoImage(image) {
      if (this.geoSelectionWindow.type === "bom") {
        this.model.bomGeoImages = this.model.bomGeoImages.map((geoImage) => {
          if (geoImage.id === image.id) {
            return {
              ...geoImage,
              loading: true,
            };
          }
          return geoImage;
        });
        restAdapter
          .delete(`/api/inquiries/${this.inquiryId}/bom/geo/${image.id}`)
          .then((response) => {
            this.model.bomGeoImages = this.model.bomGeoImages.filter(
              (geoImage) =>
                geoImage.id !== image.id ||
                geoImage.bom_item_id !== this.geoSelectionWindow.id
            );
            notification.success("Geo image removed successfully");
          })
          .catch((error) => {
            notification.error("Something went wrong.");
          });
      } else {
        this.model.traceGeoImages = this.model.traceGeoImages.filter(
          (geoImage) =>
            geoImage.id !== image.id ||
            geoImage.inquiry_trace_id !== this.geoSelectionWindow.id
        );
      }
    },
    downloadGeoImage(image) {
      window.open(image.url.replace("preview", "download"), "_blank");
    },
    setVSelectMenuProps() {
      const vSelectContainer = document.getElementById("v-select-container");
      const vSelectContainerBottom =
        vSelectContainer.getBoundingClientRect().bottom;
      const windowHeight = window.innerHeight;
      const isVSelectMenuPropsBottom =
        windowHeight - vSelectContainerBottom < 310;
      if (isVSelectMenuPropsBottom) {
        this.vSelectMenuProps = { top: true, offsetY: true };
      } else {
        this.vSelectMenuProps = { bottom: true, offsetY: true };
      }
    },
    processRepairRecycleAndResaleForPreview(companies) {
      if (!this.selectedTraceLayout.repairRecycleAndResale) return [];

      const processedItems = companies.map((company, index) => {
        return {
          id: index + 1,
          supplier_id: company.id,
          uuid: company.account?.uuid,
          company_uuid: company.uuid,
          logo: company.account?.logo,
          name: company.name,
          city: company.account?.city,
          country: company.account?.country,
          verified: company.account?.verified,
        };
      });

      return processedItems.filter((item) => item);
    },
    typingProductName() {
      if (this.model.productName && this.model.productName.length > 255) {
        this.productNameError =
          "Product name should be less than 255 characters";
      } else if (this.model.productName) {
        this.productNameError = false;
      } else {
        this.productNameError = "Product name is required";
      }
    },
    resetTheme() {
      this.selectedTraceLayout = {
        ...this.selectedTraceLayout,
        titleColor: this.traceLayout.title_color,
        fontColor: this.traceLayout.font_color,
        iconColor: this.traceLayout.icon_color,
        view: this.traceLayout.view,
        repairRecycleAndResale: this.traceLayout.repair_recycle_and_resale,
        verifiedColor: this.traceLayout.verified_color,
        unverifiedColor: this.traceLayout.unverified_color,
        backgroundColor: this.traceLayout.background_color,
      };
    },
    selectColors(field, color) {
      this.selectedTraceLayout[field] = color;
    },
    screenSizeHandler(e) {
      this.windowSize = window.innerWidth;
      this.screenScrollHandler(e);
      return this.windowSize;
    },
    screenScrollHandler(e) {
      const traceTopBar = document.getElementById("traceTopBar");
      const traceContentContainer = document.getElementById(
        "trace-content-container"
      );
      traceTopBar.style.width = traceContentContainer.offsetWidth - 42 + "px";
      traceTopBar.style.zIndex = "15";
      const isPositionFixed = traceTopBar.style.position == "fixed";

      if (!this.isDistanceRecorded) {
        this.initialDistance = traceTopBar.getBoundingClientRect().top;
        this.isDistanceRecorded = true;
      }

      if (window.scrollY > this.initialDistance && !isPositionFixed) {
        traceTopBar.style.position = "fixed";
        traceTopBar.style.top = "0";
        traceTopBar.style.zIndex = "10";
        traceContentContainer.style.paddingTop =
          traceTopBar.offsetHeight + 20 + "px";
      }

      if (window.scrollY < this.initialDistance && isPositionFixed) {
        traceTopBar.style.position = "relative";
        traceContentContainer.style.paddingTop = "20px";
      }
    },
    removeCareInstruction(item) {
      this.model.selectedCareInstruction =
        this.model.selectedCareInstruction.filter(
          (instruction) => instruction != item.id
        );
    },
    uploadThumbnail(file) {
      if (!file) return;
      if (file.size > this.maxFileSize) {
        notification.error(
          `File size exceeds the ${
            this.maxFileSize / (1024 * 1024)
          } MB limit. Please upload a smaller file.`
        );
        return;
      }

      const formData = new FormData();
      formData.append("file[]", file);
      restAdapter
        .post("/api/inquiries/files_upload/" + this.inquiryId, formData)
        .then((response) => {
          this.setDefaultImage(response.data.attachment_id);
        })
        .catch((error) => {
          console.error("Upload failed:", error.response);
          notification.error(error.response?.data?.status || "Upload error");
        });
    },
    setDefaultImage(id) {
      restAdapter
        .post(`/api/inquiries/${this.inquiryId}/set_default_image/`, {
          default_image: id,
        })
        .then((response) => {
          this.defaultImage = response?.data?.inquiry?.default_image;
          this.fetchFileInfo(id);
          notification.success("Image set as default thumbnail successfully");
        })
        .catch((error) => {
          console.error("Failed to set default image:", error.response);
          notification.error("Failed to set default image");
        });
    },

    fetchFileInfo(id) {
      const uniqueId = id || this.defaultImage;
      if (!uniqueId) {
        console.error("No ID or default image provided");
        return;
      }

      this.uploading = true;
      restAdapter
        .get(`/api/inquiries/file_info/${uniqueId}`)
        .then(({ data }) => {
          this.file = {
            url: `${this.baseUrl}/api/inquiries/file_show/${uniqueId}`,
            title: data?.title,
            size: data?.size,
            isDefault: false,
          };
        })
        .catch((error) => {
          console.error("Failed to fetch file info:", error);
        })
        .finally(() => {
          this.uploading = false;
        });
    },
    clickOnBomRecord(id) {
      if (this.model.selectedBomRecords.includes(id)) {
        this.model.selectedBomRecords = this.model.selectedBomRecords.filter(
          (record) => record != id
        );
      } else {
        this.model.selectedBomRecords.push(id);
      }
    },
    initialize() {
      restAdapter
        .get(`/api/trace/care_instructions/all_care_instructions`)
        .then((response) => {
          this.loading = false;
          this.allCareInstructions = response.data.careInstructions;
          this.careInstructions = response.data.careInstructions.filter(
            (item) => item.is_showing
          );

          if (!this.careInstructions.length) {
            this.noDataText = "No care instructions found";
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getGeoGallery() {
      restAdapter
        .get("/api/geo")
        .then((response) => {
          this.geoImages = response.data.geo_images.map((image) => ({
            ...image,
            id: image.id,
            url: `${this.baseUrl}/api/geo/preview/${image.upload_name}`,
          }));
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getSuppliers() {
      restAdapter
        .get(`/api/inquiries/${this.inquiryId}/assigned_suppliers`)
        .then((response) => {
          this.supplierDetails = [
            this.brandRegistry,
            ...response.data.inquiry_assigned_suppliers,
          ];
          this.model.itemDetails = this.model.itemDetails.map((item) => {
            const supplierFound = this.supplierDetails.find(
              (supplier) => supplier.id == item.details.supplier_id
            );
            return {
              ...item,
              details: {
                ...item.details,
                supplier_id: supplierFound ? item.details.supplier_id : null,
                factory_id: supplierFound ? item.details.factory_id : null,
              },
              verified_by_brand: supplierFound
                ? item.details.verified_by_brand
                : false,
              verified_by_factory: supplierFound
                ? item.details.verified_by_factory
                : false,
              verification_requestable: supplierFound
                ? item.details.verification_requestable
                : false,
              verification_requested: supplierFound
                ? item.details.verification_requested
                : false,
            };
          });
        });
    },
    debounceSearch: debounce(function () {
      this.suppliersCurrentPage = 1;
      this.loadingSuppliers = true;
      this.getGlobalSuppliers();
    }, 500),
    getGlobalSuppliers() {
      this.loadingSuppliers = true;
      let url =
        "/api/organization_registry?page=" +
        this.globalSuppliersCurrentPage +
        "&type=supplier";

      url += "&force_include=" + this.brandRegistry.id;
      url += this.searchName ? "&name=" + this.searchName : "";
      url += "&account=true";

      restAdapter.get(url).then((response) => {
        this.globalSuppliers = response.data.data;
        this.globalSuppliersTotalCount = response.data.total;
        this.loadingSuppliers = false;
      });
    },
    fetchMoreSuppliers($state) {
      let url = "/api/organization_registry";
      url +=
        "?page=" + (this.globalSuppliersCurrentPage + 1) + "&type=supplier";
      url += "&force_include=" + this.brandRegistry.id;
      url += this.searchName ? "&name=" + this.searchName : "";
      url += "&account=true";

      restAdapter.get(url).then((response) => {
        if (response.data.data.length) {
          this.globalSuppliers = this.globalSuppliers.concat(
            response.data.data
          );
          this.globalSuppliersCurrentPage++;
          $state.loaded();
        } else {
          $state.complete();
        }
      });
    },
    updateValueChainData() {
      this.loading = true;
      restAdapter
        .get(`/api/inquiry/${this.inquiryId}/trace`)
        .then((response) => {
          this.processValueChainData(response, true);
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    readNonConsumerData() {
      this.loading = true;
      restAdapter
        .get(`/api/inquiry/${this.inquiryId}/trace`)
        .then((response) => {
          this.allBomRecords = response.data.bom_records;
          this.model.selectedBomRecords = this.allBomRecords
            .filter((bomItem) => bomItem.is_selected)
            .map((bomItem) => bomItem.id);
          this.model.bomGeoImages = response.data.bom_geo_images;
          this.model.traceGeoImages = response.data.trace_geo_images.map(
            (image) => ({
              ...image,
              id: image.geo_image.id,
            })
          );
          this.defaultImage = response.data.default_image;
          this.model.productName = response.data.product_name;
          this.styleNumber = response.data.style_number;
          this.isPublished = response.data.is_published;
          if (this.$route.query.tracetab) {
            this.activeTraceTab = this.$route.query.tracetab;
          } else if (response.data.is_published) {
            this.activeTraceTab = "preview";
          }
          this.needTraceApproval = response.data.need_trace_approval;
          this.processValueChainData(response, false);
          this.deliveryMethods = response.data.delivery_methods;
          this.model.fabricComposition = response.data.fabric_composition;
          this.model.selectedCareInstruction =
            response.data.care_instructions ?? [];
          this.model.sustainability =
            response.data.sustainability_and_circularity;
          this.brandLogo = response.data.brand_logo;
          this.brandLink = response.data.brand_link;
          this.markWholeTraceAsUnverified =
            response.data.mark_whole_trace_as_unverified;
          this.note = response.data.note;
          this.getPublicData();
          this.loading = false;
          this.ignoreFormEdit = true;
          resetLeaveAlert();
          this.fetchFileInfo();
        })
        .catch((error) => {
          this.loading = false;
          resetLeaveAlert();
        });
    },
    processValueChainData(response, filterOutDelivery = false) {
      this.model.itemDetails = response.data.fields.map((field) => {
        if (filterOutDelivery && field.display_name == "Delivery") {
          const deliveryField = this.model.itemDetails.find(
            (item) => item.details.id === field.details.id
          );
          if (deliveryField) {
            return deliveryField;
          }
        }

        return {
          ...field,
          details: {
            ...field.details,
            id: field.details.id || field.id,
            icon: field.details.icon || field.icon,
            order: field.details.order || field.order,
            name: field.details.name || field.display_name,
            factory_id: field.details.used_supplier_as_factory
              ? this.useCompanyAsFactoryId
              : field.details.factory_id,
          },
        };
      });
      this.model.repairs = response.data.repairs.map(
        (repair) => repair.supplier
      );
      this.model.recycles = response.data.recycles.map(
        (recycle) => recycle.supplier
      );
      this.model.resales = response.data.resales.map(
        (resale) => resale.supplier
      );
      this.model.upcycles = response.data.upcycles.map(
        (upcycle) => upcycle.supplier
      );
      this.model.donations = response.data.donations.map(
        (donation) => donation.supplier
      );
    },

    updateCareInstructions(value) {
      this.careInstructions = value;
    },

    saveNote() {
      if (!this.$refs.addNoteForm.validate()) {
        return;
      }
      restAdapter
        .post(`/api/inquiry/${this.inquiryId}/trace/note`, {
          comment: this.note,
        })
        .then(() => {
          notification.success("Note saved successfully.");
        })
        .catch((error) => {
          if (
            error.response &&
            error.response.status == 400 &&
            error.response.data &&
            error.response.data.error
          ) {
            notification.errors(error.response.data.error);
          } else {
            notification.error("Something went wrong, please try again later.");
          }
        });
    },

    getPublicData() {
      const defaultPublicUrl = `${this.origin}/#/trace/${this.styleNumber}`;
      this.publicLink = defaultPublicUrl;
      restAdapter
        .post("/api/trace/generate_qr", {
          publicUrl: defaultPublicUrl,
          inquiryId: this.inquiryId,
        })
        .then((response) => {
          this.qr = response.data.qr;
          this.trackingCode = response.data.tracking_code;
        });
    },
    requestVerification(item) {
      const assignmentId = item.details.id;

      restAdapter
        .post(
          `/api/assignments/${assignmentId}/change_verification_requested_status`,
          {
            is_requested: !item.verification_requested,
            type: "trace",
          }
        )
        .then((res) => {
          this.model.itemDetails = this.model.itemDetails.map((traceItem) => {
            if (item.details.id === item.details.id) {
              return {
                ...traceItem,
                verification_requested: !item.verification_requested,
              };
            }
            return traceItem;
          });

          if (item.verification_requested)
            notification.success(`Verification request removed`);
          else notification.success(`Verification requested`);
        })
        .catch((error) => {
          notification.error(
            "Please save changes before request verification !"
          );
        });
    },

    requestVerificationForStyle() {
      this.styleVerificationRequested = true;
      restAdapter
        .post(
          `/api/inquiries/${this.inquiryId}/request_verification_from_brand`
        )
        .then(() => {
          notification.success("Verification request sent.");
        })
        .catch((error) => {
          this.styleVerificationRequested = false;
          notification.error("Something went wrong, please try again later.");
        });
    },

    requestVerificationForTransaction(assignmentId) {
      if (!assignmentId)
        return notification.error(
          "Please save changes before request verification."
        );

      this.verificationRequestedTransactions.push(assignmentId);
      restAdapter
        .post(
          `/api/assignments/${assignmentId}/send_verification_requested_notification`
        )
        .then((res) => {
          notification.success(`Verification requested successfully.`);
        })
        .catch((error) => {
          this.verificationRequestedTransactions =
            this.verificationRequestedTransactions.filter(
              (id) => id !== assignmentId
            );
          notification.error(
            "Please save changes before request verification."
          );
        });
    },

    requestVerificationForAccount(id) {
      if (!id)
        return notification.error(
          "Please save changes before request verification."
        );

      this.verificationRequestedAccounts.push(id);

      restAdapter
        .post(`/api/organization_verification_requests/${id}`)
        .then(() => {
          notification.success("Verification request sent to Seamless Source");
        })
        .catch((error) => {
          this.verificationRequestedAccounts =
            this.verificationRequestedAccounts.filter(
              (accountId) => accountId !== id
            );
          notification.error("Something went wrong, please try again later.");
        });
    },

    publish(param) {
      const answer = window.confirm("Are you sure you want to publish?");
      if (!answer) return false;
      this.submit(
        !this.isPublished && !this.needTraceApproval ? "submit" : "update",
        true
      ).then(() => {
        restAdapter
          .get(`/api/inquiry/${this.inquiryId}/trace/${param}`)
          .then((response) => {
            this.readNonConsumerData();
            if (param === "publish") {
              this.isPublished = true;
              this.needTraceApproval = false;
              this.getPublicData();
              this.activeTraceTab = "preview";
              notification.success(
                "Digital Product Passport published successfully"
              );
              this.openTraceForm();
            } else {
              this.isPublished = true;
              this.needTraceApproval = false;
              this.activeTraceTab = "preview";
              notification.success(
                "New changes for Digital Product Passport published successfully"
              );
              this.openTraceForm();
              document
                .getElementById("consumer_iframe")
                .contentWindow.location.reload();
            }
          })
          .catch((error) => {
            notification.error(error.response.data.error);
          });
      });
    },

    requestOrRemoveInquiryVerification() {
      const newStatus = !this.$store.state.inquiryVerificationStatus.requested;
      restAdapter
        .put(`/api/inquiries/${this.inquiryId}/request_verification`, {
          status: newStatus,
        })
        .then(() => {
          notification.success(
            newStatus
              ? "Verification requested"
              : "Verification request removed"
          );
          store.commit("SET_INQUIRY_VERIFICATION_STATUS", {
            ...this.$store.state.inquiryVerificationStatus,
            requested: newStatus,
          });
        });
    },

    submit(param, publishing = false) {
      return new Promise((resolve, reject) => {
        if (!this.model.productName || this.model.productName.length > 255) {
          this.productNameError = !this.model.productName
            ? "Product name is required."
            : "Product name should be less than 255 characters";
          const field = document.getElementById(`product-name-field`);
          field.scrollIntoView({
            behavior: "auto",
            block: "center",
            inline: "center",
          });
          reject("Validation error");
          return;
        }
        this.productNameError = false;

        const toSubmit = this.formatDataForSubmission();

        if (toSubmit.error) {
          const field = document.getElementById(toSubmit.error);
          field.scrollIntoView({
            behavior: "auto",
            block: "center",
            inline: "center",
          });
          reject("Validation error");
          return;
        }

        this.submitDisabled = true;

        restAdapter
          .post(`/api/inquiry/${this.inquiryId}/trace/${param}`, toSubmit)
          .then((response) => {
            this.needTraceApproval = !publishing;
            this.submitDisabled = false;
            notification.success("Changes saved successfully");
            !publishing && this.readNonConsumerData();
            resetLeaveAlert();
            resolve(response);
          })
          .catch((error) => {
            if (
              error.response.status == 400 &&
              !error.response.data.error_locations
            ) {
              notification.error(error.response.data.error);
            } else {
              notification.error(
                "Something went wrong, please try again later."
              );
            }
            this.submitDisabled = false;
            reject("Submission error");
          });
      });
    },

    formatDataForSubmission() {
      let error = false;
      const toSubmit = {
        productName: this.model.productName,
        data: [],
        fabricComposition: this.model.fabricComposition,
        careInstructions: this.model.selectedCareInstruction,
        sustainability: this.model.sustainability,
        layout: this.selectedTraceLayout,
        selectedBomItems: this.selectedTraceLayout.bom
          ? this.model.selectedBomRecords
          : [],
        repairs: this.formatCircularityCompaniesForSubmission(
          this.model.repairs
        ),
        recycles: this.formatCircularityCompaniesForSubmission(
          this.model.recycles
        ),
        resales: this.formatCircularityCompaniesForSubmission(
          this.model.resales
        ),
        upcycles: this.formatCircularityCompaniesForSubmission(
          this.model.upcycles
        ),
        donations: this.formatCircularityCompaniesForSubmission(
          this.model.donations
        ),
      };
      this.model.itemDetails.map((element, index) => {
        if (!element.details.name) {
          this.displayNameError = true;
          error = "supply-chain-" + element.details.id + "-name";
        }
        if (!element.details.icon) {
          this.iconError = true;
          error = "supply-chain-" + element.details.id + "-icon";
        }
        const data = {
          ...element.details,
          order: index + 1,
          factory_id:
            element.details.factory_id !== this.useCompanyAsFactoryId
              ? element.details.factory_id
              : null,
          used_supplier_as_factory:
            element.details.factory_id === this.useCompanyAsFactoryId,
          geo_images: this.model.traceGeoImages
            .filter((image) => image.inquiry_trace_id === element.details.id)
            .map((image) => image.geo_image.id),
        };
        toSubmit.data.push(data);
      });
      if (error) return { error: error };
      return toSubmit;
    },
    formatCircularityCompaniesForSubmission(companies) {
      return companies.map((company) => company.id);
    },
    AllCareInstructions() {
      this.showCareInstructions = true;
    },

    copying(id) {
      const element = document.getElementById(id);
      const copiedState = id === "toCopy" ? "copied" : "copiedFrameCode";

      navigator.clipboard.writeText(element.textContent).then(() => {
        this[copiedState] = true;

        setTimeout(() => {
          this[copiedState] = !this[copiedState];
        }, 5000);
      });
    },

    onChangeSupplierOrFactory(id, resetFactory) {
      this.model.itemDetails = this.model.itemDetails.map((item) => {
        if (item.details.id === id) {
          return {
            ...item,
            details: {
              ...item.details,
              factory_id: resetFactory ? null : item.details.factory_id,
              supplier: item.details.supplier_id
                ? this.supplierDetails.find(
                    (supplier) => supplier.id == item.details.supplier_id
                  )
                : null,
              factory:
                resetFactory ||
                item.details.factory_id === this.useCompanyAsFactoryId
                  ? null
                  : this.supplierDetails
                      .find(
                        (supplier) => supplier.id == item.details.supplier_id
                      )
                      ?.factories.find(
                        (factory) => factory.id == item.details.factory_id
                      ),
            },
            verified_by_brand: false,
            verified_by_factory: false,
            verification_requestable: false,
            verification_requested: false,
          };
        }
        return item;
      });
    },
    openPublicLinkDialog() {
      this.publicLinkDialog = true;
    },
    openQrCode() {
      this.qrDialog = true;
    },
    openIframeCode() {
      this.iframe = true;
    },
    onClickTraceTab(tab) {
      this.activeTraceTab = tab;
    },

    checkAssignedValueChain() {
      const alertOpen = !!this.assignedValueChain;
      this.isAlertOpen = alertOpen;
      return alertOpen;
    },

    removeValueChain() {
      this.isAlertOpen = false;
      restAdapter
        .delete(`/api/inquiries/${this.inquiryId}/remove_value_chain`, {
          value_chain_id: this.assignedValueChain.id,
        })
        .then(() => {
          store.commit(
            "SET_VALUE_CHAIN_ASSIGN_STATUS",
            !this.$store.state.valueChainAssignStatus
          );
          this.$emit("value-chain-assigned", null);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.md-double-line {
  overflow-y: scroll;
  max-height: 150px !important;
  z-index: 999 !important;
}

.v-subheader {
  padding-top: 5px;
  height: 20px;
}

.supply-chain-item-container {
  display: flex;
  background-color: white;
}

.supply-chain-drag-handle {
  cursor: grab;
  background-color: rgba(71, 48, 104, 0.05);
  height: fit-content;
  padding: 5px 0px;

  .v-icon {
    font-size: x-large;
  }
}

.supply-chain-drag-handle:active {
  cursor: grabbing;
}

.supply-chain-item {
  flex: 1;
  padding: 0px 10px;
  border-radius: 10px;
}

.supply-chain-item:hover {
  background-color: rgba(71, 48, 104, 0.05);
}

.supply-chain-icon-selector {
  position: relative;
  cursor: pointer;

  &:hover {
    ::v-deep img {
      display: none;
    }
    .add-trace-icon {
      display: block;
    }
  }
}

.add-trace-icon {
  display: none;
  position: absolute;
  top: 6px;
  left: 6px;

  .v-icon {
    font-size: 30px;
    color: #473068;
  }
}

::v-deep .supply-chain-icon-error > span > div {
  border-color: #ff5252 !important;
  box-shadow: rgba(255, 82, 82, 0.17) 0px 4.44194px !important;
}

::v-deep .supply-chain-icon-selector:hover > span > div {
  width: 42px !important;
  height: 42px !important;
}

.supply-chain-delete-icon {
  color: #949494;
  cursor: pointer;
}

.supply-chain-delete-icon:hover {
  font-size: x-large;
}

.supply-chain-add-icon {
  text-align: left;
  width: fit-content;
  margin: 10px 0px 30px 10px;
}
.supply-chain-add-icon > .v-icon {
  font-size: 35px;
  color: #473068;
  cursor: pointer;
}

::v-deep .supply-chain-add-icon:hover > .v-icon {
  font-size: 38px;
}

.search-result {
  position: absolute;
  margin-top: -25px;
  margin-left: 20px;
  z-index: 99;
  max-height: 300px;
  width: 28.5% !important;
  overflow-y: auto;
}

.main_container {
  width: 100%;
  height: 50%;
  padding: 20px;
}

.text-field {
  padding-top: 20px;
}
.logo_btn {
  border: 1px solid #492a67;
  margin-top: 22px;
  margin-left: -40px;
  height: 38px;
  color: #492a67;
}
.color_stroke::v-deep .md-progress-spinner-circle {
  stroke: #492a67 !important;
}

.lable_size {
  height: 50px;
  display: flex;
  align-items: center;
  flex-direction: row;
  width: auto;
  padding-top: 20px;
  text-align: left;
  font-size: 20px;
  color: #582963;
  font-weight: bold;
}

.product-image-checkbox::v-deep .v-input--selection-controls__input {
  margin-right: 0px;
}

.note-container-custom {
  background-color: #ffffff;
  padding: 20px;
  margin-top: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
}

.note-field-custom {
  flex: 1;
}

.note-button-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.button-custom {
  background-color: #582963 !important;
  color: #eef0fb !important;
  border-radius: 8px;
  margin: 11px 0px 10px 0px;
}

.radio_group_size {
  padding-left: 80px;
  padding-top: 15px;
}

.deliveryM_radio {
  padding-right: 50px;
}

.spinner-box {
  position: relative;
}

.no-suppliers-text {
  font-size: 14px;
  cursor: pointer;
  color: #442d65;
}

.unable-to-find-factory:hover,
.no-suppliers-text:hover {
  text-decoration: underline;
  color: #c6a8ec;
}

.unable-to-find-factory {
  @extend .no-suppliers-text;
  position: sticky;
  top: 0px;
  padding-top: 8px;
  margin: -8px 0px 8px 0px;
  background-color: inherit;
  z-index: 1;
}

.mx-auto {
  margin-left: 10px;
  max-height: 45px;
  width: 90%;
  overflow-x: hidden;
  overflow-y: auto;
}

.description-container {
  text-align: left !important;
  max-width: 250px !important;
}

.style {
  background-color: #582963;
  width: 100% !important;
  height: 100% !important;
}

.add_button {
  min-width: 10px !important;
  margin: 0px 5px !important;
  border: 1px solid #c6a8ec;
}
.remove_button {
  min-width: 10px !important;
  margin: 0px 5px !important;
  border: 1px solid red;
}
.message-div {
  float: left;
}
.message-waiting {
  background-color: #70d0ce;
  color: white !important;
  padding: 2px 5px 2px 5px;
  margin-right: 5px;
}
.message-changes-waiting {
  background-color: #6a7895;
  color: white !important;
  padding: 2px 5px 2px 5px;
  margin-right: 5px;
}
.message-done {
  background-color: #c6a8ec;
  color: white !important;
  padding: 2px 5px 2px 5px;
  margin-right: 5px;
}

.section-container {
  padding: 20px;
  margin-top: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.text-field-custom {
  width: 100%;
}
.top-header-tools {
  z-index: 20;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap-reverse;
  background-color: #fafafa;
}
.url-div {
  border: 0.5px solid;
  border-color: #501d5f !important;
  border-radius: 8px;
  padding: 6px;
  text-align: left;
  width: fit-content;
  margin-bottom: 40px;
}
.url-span {
  color: #c2aae8;
  padding: 2px 5px 2px 5px;
  margin-right: 5px;
}
.view_all {
  margin: auto;
  border: 1px solid #492a67;
  color: #492a67;
}

.image {
  object-fit: contain;
  max-height: 140px;
  max-width: 100%;
  border-radius: 7px;
}
.bar_color::v-deep .md-progress-bar-track:after {
  background-color: #582963 !important;
}

.md-progress-bar.md-theme-default.md-indeterminate,
.md-progress-bar.md-theme-default.md-query {
  background-color: #c6a8ec !important;
  background-color: var(--md-theme-default-primary-on-, #c6a8ec) !important;
}

.bar_color::v-deep .md-progress-bar-fill:after {
  background-color: #582963 !important;
}

.qr-code-comp {
  padding: 10px;
}
.qr-code-button {
  color: white !important;
  border: 2px solid;
  border-radius: 8px;
  margin: 11px 0px 10px 0px;
}

.care_btn_container {
  text-align: right;
  margin: 13px 15px 0 0px;
  font-size: 20px;
}

.bom-items-flexbox {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding-top: 20px;
  border-top: 1px solid #eff0f5;
  margin-left: 20px;
  flex-wrap: wrap;
  justify-content: space-between;
}

.bom-item-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
}

.color-selection-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: -25px;
  margin-bottom: -25px;
}

.color-selection-item {
  align-content: center;
  width: calc((100% / 8) - 30px);
  min-width: 95px;
}

.color-reset-item {
  align-content: flex-end;
  width: calc((100% / 8) - 30px);
  min-width: 95px;
}

@media only screen and (min-width: 1264px) {
  ::v-deep .bom-item-wrapper {
    width: calc((100% / 3) - 30px) !important;
  }
}

@media only screen and (max-width: 1263px) {
  ::v-deep .bom-item-wrapper {
    width: calc((100% / 2) - 30px) !important;
  }
}

@media only screen and (max-width: 810px) {
  ::v-deep .bom-item-wrapper {
    width: calc(100%) !important;
  }
}

.view_care_instructions {
  background-color: #501d5f !important;
  color: white !important;
  border-color: #501d5f !important;
  border: 2px solid;
  border-radius: 8px;
}

.select-care-instructions >>> .v-chip__content {
  font-size: 15px;
  font-weight: 500;
}

.care-instruction-img {
  width: 50px;
  margin-right: 5px;
}

.more-info-title {
  margin-left: 15px;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #473068;
}

.title-underline {
  border-bottom: 1px solid #70d0ce;
  width: fit-content;
}

.more-info-container {
  margin-top: 0px;
  text-align: left;
  min-height: 50px;
  width: 95%;
}

.feedback-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon-container {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.request-container {
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
}

.request-item {
  text-align: left;
  margin: 0px 10px 10px 0px;
  padding: 10px;
  color: #473068;
  background: white;
  border-radius: 12px;
}

.buyer-request {
  background-color: #70d0ce;
}
.ddp-code {
  text-align: left;
  font-weight: bolder;
  font-size: 15px;
  padding-right: 65px;
  margin: 0px 10px 10px 10px;
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 5px;
}
.hover-container {
  max-width: 200px;
  word-wrap: anywhere;
}
.code {
  background-color: unset !important;
  color: #000000;
  padding: 10px;
  font-family: "Courier New", Courier, monospace;
}

.tag {
  color: #0000ff;
}

.attr {
  color: #008000;
}

.string {
  color: #800080;
}

.trace-tab-buttons-container {
  align-self: flex-start;
  width: fit-content;
  height: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
  background-color: #faf7ff;
  border-radius: 20px;
  border: 1px solid #473068;
  margin: 20px 0px;
}
.trace-tab-button {
  height: 30px;
  width: 90px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px;
  color: #473068;
  cursor: pointer;
}
.trace-tab-button-active {
  background-color: white;
  border: 1px solid #473068;
  border-radius: 15px;
  cursor: initial;
}
.no-location-warning {
  font-size: 12px;
  color: $infoMessage;
  margin-top: -25px;
}
.reset-button::v-deep .md-ripple {
  z-index: unset;
}
.reset-button::v-deep .md-button-content {
  z-index: unset;
}
.view-selector::v-deep label {
  color: #442d65 !important;
}
.view-selector::v-deep .v-icon {
  color: #442d65;
}

.error-message {
  color: red;
  font-size: 14px;
  margin-top: 10px;
}
</style>

<style lang="scss" scoped>
.geo-image-selector-button {
  cursor: pointer;
}

.geo-image-selector-button:hover {
  svg {
    height: 30px;
    width: 30px;
  }
}

.not-verified-text {
  color: #ff0000;
  text-align: left;
  margin-bottom: 10px;
}

.ghost {
  opacity: 0;
  padding: 10px;
}
</style>
