<template>
  <div>
    <div v-if="isSuccess" class="verified">
      <svg
        width="15"
        height="15"
        viewBox="0 0 28 29"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="0.8"
          y="1.3"
          width="26.4"
          height="26.4"
          rx="13.2"
          stroke="#9483AD"
          stroke-width="1.6"
        />
        <path
          d="M10.1862 20.8325C9.99041 20.8325 9.79509 20.7541 9.64453 20.5982L5.93577 16.7437C5.63048 16.4258 5.62482 15.9051 5.92372 15.5804C6.22359 15.2562 6.71338 15.2502 7.01919 15.5676L10.2042 18.8771L20.9978 8.37969C21.3134 8.07327 21.8029 8.09625 22.092 8.43063C22.3805 8.76579 22.3594 9.28643 22.0433 9.59259L10.71 20.6163C10.5614 20.7605 10.3742 20.8326 10.1865 20.8326L10.1862 20.8325Z"
          fill="#473068"
        />
      </svg>
      <span>{{ message }}</span>
    </div>
    <div v-else class="unverified">
      <svg
        width="15"
        height="15"
        viewBox="0 0 28 29"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="0.8"
          y="1.3"
          width="26.4"
          height="26.4"
          rx="13.2"
          stroke="#BF5E5E"
          stroke-width="1.6"
        />
        <path
          d="M8.58789 19.912L14.0009 14.499L19.414 19.912M19.414 9.08594L13.9999 14.499L8.58789 9.08594"
          stroke="#C11D1D"
          stroke-width="1.54865"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
      <span>{{ message }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "TraceInfoMessage",
  props: {
    isSuccess: {
      type: Boolean,
      default: false,
    },
    message: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
.verified {
  display: flex;
  align-items: center;
  text-align: left;
  font-weight: 350;
  width: max-content;
  padding: 2px 5px;
  margin: 11px 5px 5px 3px;
  border-radius: 8px;
  gap: 4px;
  color: #473068;
  background: rgba(236, 234, 240, 1);
}
.unverified {
  @extend .verified;
  color: #c67d7d;
  background: rgba(236, 234, 240, 1);
}
</style>
